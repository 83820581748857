import React, { useState } from 'react';
import { Tamanho } from './styles';

interface ITamanhos {
  codbar?: string;
  setTamanhoSelecionado: any;
  tamanhos: IListaTamanhos[];
}

interface IListaTamanhos {
  tamanho: string;
  selecionado: boolean;
}

export default function Tamanhos({ setTamanhoSelecionado, tamanhos }: ITamanhos) {
  const [listaTamanhos, setListaTamanhos] = useState<IListaTamanhos[]>(tamanhos || []);

  return (
    <>
      {listaTamanhos.length > 0 && listaTamanhos.map((tamanho: IListaTamanhos, index: number) => (
        <Tamanho
          key={index}
          selecionado={tamanho.selecionado}
          onClick={() => {
            const filtroTamanhos = listaTamanhos.map((listaTamanho: IListaTamanhos) => {
              if (tamanho.tamanho === listaTamanho.tamanho) {
                return { tamanho: listaTamanho.tamanho, selecionado: true };
              }
              return { tamanho: listaTamanho.tamanho, selecionado: false };
            });

            setTamanhoSelecionado(tamanho.tamanho);
            setListaTamanhos(filtroTamanhos);
          }}
        >
          {tamanho.tamanho}
        </Tamanho>
      ))}
    </>
  );
}
