import React, { useState, useEffect, useContext, useRef } from 'react';
import * as FaIcons from 'react-icons/fa';
import { useNavigate } from 'react-router-dom';
import { Link } from 'react-router-dom';
import { toast } from 'react-toastify';
import * as AiIcons from 'react-icons/ai';

import { CloseDiv, Container, FinalizarCarrinhoDivCustom } from './styles';

import {
  FinalizarButton,
  FinalizarDiv,
  MobilePrecoDiv, ProdutoMobileDiv, ProdutoMobileImage,
  ProdutoMobileImageDiv, ProdutoMobileInfoDiv, QuantidadeButton,
  QuantidadeDiv, QuantidadeInput, QuantidadeInputDiv, TotaisDiv, TotaisFinalizarDiv
} from '../../pages/Carrinho/styles';

import Context, { ICarrinho, IContext } from '../../context/Context';
import { formatCurrency } from '../../utils/formatCurrency';
import { deleteItemCarrinho, postItemCarrinho } from '../../pages/ProdutoDetalhes';
import semFoto from '../../assets/images/sem-foto.jpg';

interface ISideBarCarrinho {
  isOpen: boolean;
  setIsOpen: (isLoading: boolean) => void;
}

export default function SideBarCarrinho({ isOpen, setIsOpen }: ISideBarCarrinho) {
  const { configs, carrinho, dadosLogin, setCarrinho }: IContext = useContext(Context);
  const navigate = useNavigate();
  const ref = useRef<HTMLDivElement>(null);

  const [itensCarrinho, setItensCarrinho] = useState<ICarrinho[]>([]);

  //Configs
  const [logoURI, setLogoURI] = useState<string>('');
  const [necCadAutMosPro, setNecCadAutMosPro] = useState<boolean>(false);
  const [venAciEst, setVenAciEst] = useState<boolean>(true);
  const [valMinCom, setValMinCom] = useState<string>('0');
  const [valFreGra, setValFreGra] = useState<string>('0');

  const subTotal = itensCarrinho.reduce((accumulator: any, currentValue: any) => accumulator + currentValue?.valor * +currentValue?.quantidade, 0);

  const handleToggle = () => setIsOpen(!isOpen);

  async function incrementarQuantidade(codmer: number) {
    const novoCarrinho = itensCarrinho.map((item: ICarrinho) => {
      if (item.codmer === codmer) {
        if (!venAciEst && item?.estatu) {
          if (+item.quantidade + 1 > +item?.estatu) {
            toast.warning(`${item.mer} ${item?.cor?.padmer || ''} ${item?.codtam || ''} - Quantidade inválida. Estoque atual: ` + item?.estatu);
            return item;
          }
        }

        const itemCarrinhoAtualizado = {
          cod: item.cod, codmer: item.codmer,
          codapp_user: dadosLogin.id, qua: +item.quantidade + 1
        };

        postItemCarrinho(itemCarrinhoAtualizado);

        return { ...item, quantidade: String(+item.quantidade + 1) };
      }

      return item;
    });
    setCarrinho(novoCarrinho);
    // localStorage.setItem('@Carrinho', JSON.stringify(novoCarrinho));
    setItensCarrinho(novoCarrinho);
  }

  function decrementarQuantidade(codmer: number) {
    const novoCarrinho = itensCarrinho.map((item: ICarrinho) => {
      if (item.codmer === codmer) {
        if (+item.quantidade > 1) {
          const itemCarrinhoAtualizado = {
            cod: item.cod, codmer: item.codmer,
            codapp_user: dadosLogin.id, qua: +item.quantidade - 1
          };
          postItemCarrinho(itemCarrinhoAtualizado);
          return { ...item, quantidade: String(+item.quantidade - 1) };
        }
        deleteItemCarrinho(item.cod);
        return;
      }
      return item;
    });

    const novoCarrinhoFilter = novoCarrinho.filter((carrinho: any) => carrinho);
    setCarrinho(novoCarrinhoFilter);
    // localStorage.setItem('@Carrinho', JSON.stringify(novoCarrinhoFilter));
    setItensCarrinho(novoCarrinhoFilter);
  }

  function alterarQuantidade(quantidade: number | string, codmer: number) {
    if (quantidade == '0') {
      toast.warning('Quantidade inválida');
      return;
    }

    const novoCarrinho = itensCarrinho.map((item: ICarrinho) => {
      if (item.codmer === codmer) {
        const itemCarrinhoAtualizado = {
          cod: item.cod, codmer: item.codmer,
          codapp_user: dadosLogin.id, qua: Math.round(+quantidade)
        };
        postItemCarrinho(itemCarrinhoAtualizado);
        return { ...item, quantidade: String(Math.round(+quantidade)) };
      }
      return item;
    });
    setCarrinho(novoCarrinho);
    setItensCarrinho(novoCarrinho);
  }

  function ValorMinimoCompraCaption() {
    if (!dadosLogin?.autverprosit) {
      return (
        <></>
      );
    }

    if (valMinCom !== '0' && subTotal < +valMinCom) {
      const falta = +valMinCom - subTotal;

      return (
        <p style={{ width: '100%', color: 'red', textAlign: 'justify', cursor: 'default' }}>
          Valor minimo de compra: {formatCurrency(+valMinCom)} em produtos. Adicione mais {formatCurrency(falta)}
        </p>
      );
    }

    return (
      <></>
    );
  }

  function handleClickForaDoComponente(e: any) {
    if (ref.current && !ref.current.contains(e.target) && isOpen) {
      handleToggle();
    }
  }

  function validaFrete(): string {
    if (valFreGra === '0') return 'A Calcular';
    if (subTotal >= +valFreGra) return 'Grátis';

    return 'A Calcular';
  }

  useEffect(() => {
    if (configs.length > 0) {
      const [{ val: uri }] = configs.filter((config: any) => config.gru === 'logo');
      const [{ val: CadAutMosPro }] = configs.filter((config: any) => config.con === 'NecCadAutMosPro');
      const [{ val: VenAciEst }] = configs.filter((config: any) => config.con === 'VenAciEst');
      const [{ val: ValMinCom }] = configs.filter((config: any) => config.con === 'ValMinCom');
      const [{ val: valFreGra }] = configs.filter((config: any) => config.con === 'valfregra');

      setLogoURI('https://' + uri);
      setNecCadAutMosPro(Boolean(+CadAutMosPro));
      setVenAciEst(Boolean(+VenAciEst));
      setValMinCom(ValMinCom);
      setValFreGra(valFreGra);

    }
  }, [configs]);

  useEffect(() => {
    if (carrinho.length > 0) {
      setItensCarrinho(carrinho);
      return;
    }

    setItensCarrinho([]);
  }, [carrinho]);

  useEffect(() => {
    document.addEventListener('mousedown', handleClickForaDoComponente);
    return () => {
      document.removeEventListener('mousedown', handleClickForaDoComponente);
    };
  });

  useEffect(() => {
    if (isOpen) {
      setTimeout(() => {
        const cartSidebar = document.getElementById('teste');
        if (cartSidebar) {
          cartSidebar.scrollTo({
            top: document.body.scrollHeight,
            left: 0,
            behavior: 'smooth'
          });
        }
      }, 300);
    }

  }, [isOpen]);

  return (
    <Container
      isOpen={isOpen}
      ref={ref}
      id='teste'
    >
      <CloseDiv>
        <FaIcons.FaWindowClose size={25} onClick={handleToggle} />
      </CloseDiv>

      <Link to={'/'}>
        {logoURI && <img src={logoURI} alt='Logo da Empresa' />}
      </Link>
      <b>Meu Carrinho</b>
      <hr />
      {itensCarrinho.length > 0 && itensCarrinho.map((itemCarrinho: ICarrinho, index: number) => (
        <React.Fragment key={index}>
          <ProdutoMobileDiv>
            <ProdutoMobileImageDiv
              onClick={() => navigate(`/produtoDetalhes/${itemCarrinho.codbar}/${itemCarrinho.mer.replaceAll(' ', '-')}`,
                { state: { caminho: 'Home' } })
              }
            >
              <ProdutoMobileImage
                src={itemCarrinho?.cor?.linkFot}
                alt={`Imagem do produto ${itemCarrinho?.mer}`}
                onError={(e: any) => {
                  e.target.onerror = null; // Evita loops infinitos caso a imagem de substituição também não seja encontrada
                  e.target.src = semFoto;
                }}
              />
            </ProdutoMobileImageDiv>
            <ProdutoMobileInfoDiv>
              <span>{itemCarrinho?.mer}{' '}{itemCarrinho?.codtam}{' '}{itemCarrinho?.cor?.padmer}</span>
              <MobilePrecoDiv>
                {necCadAutMosPro ? dadosLogin.autverprosit === 1 ?
                  <b>{formatCurrency(itemCarrinho?.valor)}</b> : <b></b> :
                  <b>{formatCurrency(itemCarrinho?.valor)}</b>
                }
                <div />
                <QuantidadeDiv>
                  <QuantidadeInputDiv>
                    <QuantidadeButton
                      onClick={() => decrementarQuantidade(itemCarrinho?.codmer)}
                    >
                      -
                    </QuantidadeButton>
                    <QuantidadeInput
                      type={'number'}
                      value={itemCarrinho.quantidade}
                      isInvalid={!venAciEst && itemCarrinho?.estatu ? +itemCarrinho.quantidade > itemCarrinho.estatu : false}
                      onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
                        if (+e.target.value < 0) {
                          return;
                        }
                        alterarQuantidade(e.target.value, itemCarrinho?.codmer);
                      }}
                      onBlur={(e: React.ChangeEvent<HTMLInputElement>) => {
                        if (!venAciEst && itemCarrinho?.estatu) {
                          if (+e.target.value > itemCarrinho.estatu) {
                            alterarQuantidade(itemCarrinho.estatu, itemCarrinho?.codmer);
                            toast.warning(`${itemCarrinho.mer} ${itemCarrinho?.cor?.padmer || ''} ${itemCarrinho?.codtam || ''} - Quantidade inválida. Estoque atual: ` + itemCarrinho?.estatu);
                            return;
                          }
                        }

                        if (e.target.value === '' || e.target.value === '0') {
                          alterarQuantidade('1', itemCarrinho?.codmer);
                        }
                      }}
                    />
                    <QuantidadeButton
                      onClick={() => incrementarQuantidade(itemCarrinho?.codmer)}
                    >
                      +
                    </QuantidadeButton>
                  </QuantidadeInputDiv>
                </QuantidadeDiv>
              </MobilePrecoDiv>
            </ProdutoMobileInfoDiv>
          </ProdutoMobileDiv>
          <hr />
        </React.Fragment>
      ))}
      <FinalizarDiv>
        <FinalizarCarrinhoDivCustom>
          <TotaisDiv>
            <TotaisFinalizarDiv>
              <span>SubTotal</span>
              {necCadAutMosPro ? dadosLogin.autverprosit === 1 ?
                <span>
                  {formatCurrency(subTotal)}
                </span> : <span>-</span> :
                <span>
                  {formatCurrency(subTotal)}
                </span>
              }
            </TotaisFinalizarDiv>
            <TotaisFinalizarDiv>
              <span>Frete</span>
              <span>
                {validaFrete()}
              </span>
            </TotaisFinalizarDiv>
            <TotaisFinalizarDiv>
              <b>Total</b>
              {necCadAutMosPro ? dadosLogin.autverprosit === 1 ?
                <b>
                  {formatCurrency(subTotal)}
                </b> : <b>-</b> :
                <b>
                  {formatCurrency(subTotal)}
                </b>
              }
            </TotaisFinalizarDiv>
          </TotaisDiv>
          <ValorMinimoCompraCaption />
          <FinalizarButton
            disabled={carrinho.length === 0}
            onClick={() => {
              handleToggle();
              navigate('/carrinho');
            }}
          >
            Finalizar Compra
            <AiIcons.AiOutlineShoppingCart style={{ marginLeft: 10 }} size={25} />
          </FinalizarButton>
          <FinalizarButton
            onClick={handleToggle}
            backgroundColor='#229a00'
          >
            Continuar Comprando
            <AiIcons.AiOutlineShopping style={{ marginLeft: 10 }} size={25} />
          </FinalizarButton>
        </FinalizarCarrinhoDivCustom>
      </FinalizarDiv>
    </Container>
  );
}
