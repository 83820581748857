import styled from 'styled-components';
import { AccordionTitle } from '../Accordion/style';

interface IAccordion {
  isActive?: boolean;
  width?: string;
}

export const Container = styled.div<IAccordion>`
  display: flex;
  flex-direction: column;
  ${({ width }) => width ? `width: ${width};` : 'width: 100%;'}
`;

export const AccordionItem = styled.div`
`;

export const AccordionButtonCustom = styled(AccordionTitle)<IAccordion>`
  background-color: #000;
  color: #fff;
  opacity: ${({ isActive }) => isActive ? '0.9' : '0.7'};

  :hover {
    background-color: #000;
    opacity: 0.8;
  }
`;
