import axios from 'axios';
import * as Sentry from '@sentry/react';

const api = axios.create({
  baseURL: import.meta.env.VITE_API_URL,
});

// Intercepta as respostas
api.interceptors.response.use(
  response => response,
  error => {
    if (error.response) {
      // Captura o erro no Sentry se a resposta não for OK
      Sentry.captureException(new Error(`HTTP error! Status: ${error.response.status}`), {
        extra: {
          url: error.config.url,
          method: error.config.method,
          status: error.response.status,
          statusText: error.response.statusText,
          data: error.response.data,
        },
      });
    } else {
      // Captura erros de rede no Sentry
      Sentry.captureException(error, {
        extra: {
          url: error.config.url,
          method: error.config.method,
        },
      });
    }
    return Promise.reject(error);
  }
);

export default api;
