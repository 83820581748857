import React, { useState, useEffect, useContext } from 'react';
import * as AiIcons from 'react-icons/ai';

import { Button } from '../ProdutoDetalhes/styles';

import BannerPrimario from '../../components/BannerPrimario';
import BarInfo from '../../components/BarInfo';
import Newsletter from '../../components/Newsletter';
import ProdutosDestaque from '../../components/ProdutosDestaque';
import { Container, LogoRodape } from './styles';
import Footer from '../../components/Footer';
import BannersPersonalizados from '../../components/BannersPersonalizados';
import Context, { IContext } from '../../context/Context';

import api from '../../services/api';
import { useNavigate } from 'react-router-dom';

export default function Home() {
  const { configs, dadosLogin }: IContext = useContext(Context);
  const navigate = useNavigate();

  //configs
  const [logoURI, setLogoURI] = useState<string>('');

  function sentryErrorTest() {
    throw new Error('Sentry Error Test');
  }

  useEffect(() => {
    window.scrollTo({ top: 0, behavior: 'smooth' });
  }, []);

  useEffect(() => {
    if (configs.length > 0) {
      const [{ val: uri }] = configs.filter((config: any) => config.gru === 'logo');
      setLogoURI('https://' + uri);
    }
  }, [configs]);

  return (
    <>
      <Container>
        <BannerPrimario />
        <BarInfo />
        <BannersPersonalizados />
        <ProdutosDestaque />
        {api.defaults.baseURL !== 'https://jaquelinebueano-api-f2468213c8d5.herokuapp.com/api' && <Newsletter />}
        {dadosLogin.id === 0 &&
          <Button
            backgroundColor='#000'
            onClick={() => navigate('/login/cadastrar')}
          >
            Clique Aqui Para Fazer Seu Cadastro
            <AiIcons.AiOutlineUserAdd style={{ marginLeft: 10 }} size={25} />
          </Button>}
        {dadosLogin.username === 'gustavotiburcio23@gmail.com' &&
          <Button
            backgroundColor='#000'
            onClick={() => sentryErrorTest()}
          >
            Teste Sentry Error
            <AiIcons.AiFillWarning style={{ marginLeft: 10 }} size={25} />
          </Button>
        }
        <LogoRodape src={logoURI} alt='Logo da Empresa' />
        <Footer />
      </Container>
    </>
  );
}
