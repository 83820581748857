import styled from 'styled-components';

interface IInput {
  width?: string | number;
  background?: string;
}

export const Input = styled.select<IInput>`
  padding: 10px;
  background: ${({ background }) => background ? background : '#fafafa'};
  border: none;
  ${({ width }) => width ? `width: ${width};` : ''}

  font-weight: 500;

  :nth-child(2n) {
    margin: 5px 0px
  }
`;
