import React, { useState } from 'react';
import { AccordionButtonCustom, AccordionItem, Container } from './styles';
import { FaCreditCard, FaPix } from 'react-icons/fa6';
import { toast } from 'react-toastify';

interface IPaymentAccordion {
  width?: string;
  setForPag: (forPag: any) => void;
  forPagSelecionado: string;
  options: {
    title: string;
    forPag: string;
    content: JSX.Element;
    iconName?: string;
  }[];
}

export default function PaymentAccordion({ options, width, setForPag, forPagSelecionado }: IPaymentAccordion) {
  const [selectedOption, setSelectedOption] = useState<null | number>(null);

  function toggleOption(index: number) {
    setSelectedOption(selectedOption === index ? null : index);

    if (options[index].forPag !== forPagSelecionado) {
      setForPag(options[index].forPag);
      toast.success('Selecionado ' + options[index].title + ' ✔');
    }

  }

  return (
    <Container width={width}>
      {options.map((option, index) => (
        <AccordionItem key={index}>
          <AccordionButtonCustom
            onClick={() => toggleOption(index)}
            aria-expanded={selectedOption === index}
            isActive={option.forPag === forPagSelecionado}
          >
            {option.title}
            {option.iconName === 'FaCreditCard' && <FaCreditCard size={25} color={'#fff'} />}
            {option.iconName === 'FaPix' && <FaPix size={25} color={'#fff'} />}
          </AccordionButtonCustom>
          {selectedOption === index && (
            <>
              {option.content}
            </>
          )}
        </AccordionItem>
      ))}
    </Container>
  );
}
