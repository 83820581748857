import React from 'react';
import { Input } from './styles';

interface ISelectInput {
  value?: string;
  onChange: (change: any) => void;
  defaultText?: string;
  options: ISelectOptions[];
  width?: string | number;
  background?: string;
}

export interface ISelectOptions {
  value: string;
  text: string;
}

export default function SelectInput({ value, defaultText, onChange, options, width, background }: ISelectInput) {

  return (
    <>
      <Input
        value={value}
        onChange={e => onChange(e.target.value)}
        width={width}
        background={background}
      >
        <option value='' disabled hidden > {defaultText ? defaultText : '-- Ordenação -- '}</option>
        {options.map((option: any, index: number) =>
          <option
            key={index}
            value={option.value}>
            {option.text}
          </option>
        )}
      </Input>
    </>
  );
}
