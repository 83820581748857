export function detectarBandeiraCartao(cartaoNumero: string): string {
  // Expressões regulares para identificar as bandeiras dos cartões
  const regexVisa = /^4[0-9]{12}(?:[0-9]{3})?$/;
  const regexMaster = /^5[1-5][0-9]{14}$/;
  const regexAmex = /^3[47][0-9]{13}$/;
  const regexDiscover = /^6(?:011|5[0-9]{2})[0-9]{12}$/;
  const regexDiners = /^3(?:0[0-5]|[68][0-9])[0-9]{11}$/;
  const regexJCB = /^(?:2131|1800|35\d{3})\d{11}$/;
  const regexElo = /^((((636368)|(438935)|(504175)|(451416)|(636297))\d{0,10})|((5067)|(4576)|(4011))\d{0,12})$/;
  const regexAura = /^(5078\d{2})(\d{2})(\d{11})$/;
  const regexHipercard = /^(606282\d{10}(\d{3})?)|(3841\d{15})$/;
  const regexHiper = /^(606282\d{10}(\d{3})?)|(3841\d{15})$/;

  // Testar o número do cartão com as expressões regulares
  if (regexVisa.test(cartaoNumero)) return 'Visa';

  if (regexMaster.test(cartaoNumero)) return 'Master';

  if (regexAmex.test(cartaoNumero)) return 'Amex';

  if (regexDiscover.test(cartaoNumero)) return 'Discover';

  if (regexDiners.test(cartaoNumero)) return 'Diners';

  if (regexJCB.test(cartaoNumero)) return 'JCB';

  if (regexElo.test(cartaoNumero)) return 'Elo';

  if (regexAura.test(cartaoNumero)) return 'Aura';

  if (regexHipercard.test(cartaoNumero)) return 'Hipercard';

  if (regexHiper.test(cartaoNumero)) return 'Hiper';

  return '';

}
