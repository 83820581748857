enum Events {
  page_view = 'page_view',
  view_item = 'view_item',
  add_to_cart = 'add_to_cart',
  begin_checkout = 'begin_checkout',
  purchase = 'purchase',
}

type TEvents = keyof typeof Events;

export function gtmEventPush(eventName: TEvents, data: object) {
  window.dataLayer.push({ event: eventName, ...data });
}
