import styled, { keyframes } from 'styled-components';
import { fadeInUp } from 'react-animations';
import { Button } from '../../pages/ProdutoDetalhes/styles';

const fadeInUpAnimation = keyframes`${fadeInUp}`;

interface CardProps {
  tipoCardImagem?: string;
}

export const CardContainer = styled.div<CardProps>`
  display:  flex;
  flex-direction: column;
  height: auto;
  background-color: transparent;
  text-align: start;
  animation: 0.5s ${fadeInUpAnimation};
  width: 360px;
  margin: 1rem 0px;

  @media screen and (max-width: 767px){
    width: 150px;
  }
`;

export const CardImage = styled.img<CardProps>`
  object-fit: contain!important;
  max-width: 100%;
  height: 80%;
  box-shadow: 0 4px 8px 0 rgba(171, 183, 183,0.2), 0 3px 10px 0 rgba(171, 183, 183,0.5);
  cursor: pointer;
  max-height: ${({ tipoCardImagem }) => tipoCardImagem === 'paisagem' ? '356px' : '592px'};
  min-height: ${({ tipoCardImagem }) => tipoCardImagem === 'paisagem' ? '356px' : '592px'};

  :hover {
    box-shadow: 0 8px 16px 0 rgba(171, 183, 183,0.2), 0 6px 20px 0 rgba(171, 183, 183,0.5);
  }

  @media screen and (max-width: 767px){
    min-height: ${({ tipoCardImagem }) => tipoCardImagem === 'paisagem' ? '150px' : '356px'};
    height: ${({ tipoCardImagem }) => tipoCardImagem === 'paisagem' ? '150px' : '356px'};
    min-width: ${({ tipoCardImagem }) => tipoCardImagem === 'paisagem' ? '150px' : '231px'};
    ${({ tipoCardImagem }) => tipoCardImagem === 'paisagem' ? 'width: 150px' : ''};
  }

  @media screen and (max-width: 400px){
    min-height: ${({ tipoCardImagem }) => tipoCardImagem === 'paisagem' ? '150px' : '356px'};
    height: ${({ tipoCardImagem }) => tipoCardImagem === 'paisagem' ? '150px' : '356px'};
    min-width: 150px;
  }
`;

export const TextDiv = styled.div`
  margin-top: 10px;
  display: flex;
  flex-direction: column;
  font-weight: 450;
  height: 10%;


  .nomeProduto {
    text-transform: capitalize;
    line-height: 1rem;
    min-height: 2rem;
    max-height: 2rem;
    overflow: hidden;
    text-overflow: ellipsis;
  }

  @media screen and (max-width: 767px){
    font-size: 0.8rem;
    height: 20%;
  }
`;

export const AvisoText = styled.b`
  color: red;

  u{
    cursor: pointer;
  }
`;

export const ButtonCustom = styled(Button)`
  margin:0;
  padding: 20px 20px;
  width: 100%;
  align-self: center;
  margin-top: 10px;
`;

export const ModalImagesContainer = styled.div`
  display: flex;
  width: 30%;

  img {
    max-height: 592px;
    max-width: 356px;
  }

  .control-next.control-arrow:before {
      content: '';
      border: solid #000;
      border-width: 0 8px 8px 0;
      display: inline-block;
      padding: 10px;
      transform: rotate(-45deg);
      -webkit-transform: rotate(-45deg);
      margin-right: 1rem;
    }

  .control-prev.control-arrow:before {
    content: '';
    border: solid #000;
    border-width: 0 8px 8px 0;
    display: inline-block;
    padding: 10px;
    transform: rotate(135deg);
    -webkit-transform: rotate(135deg);
    margin-left: 1rem;
  }
`;

export const ModalInfoContainer = styled.div`
  display: flex;
  flex-direction: column;
  width: 69%;
  margin-left: 1%;

  b {
    color: #333;
    font-size: 1.5rem;
    font-weight: bold;
  }
  span {
    color: grey;
    font-size: 1.5rem;
    margin-left: 5rem;
    font-weight: bold;
  }
`;

export const CorTamanhoDiv = styled.div`
  display: flex;
  flex-direction: column;
  margin: 1rem 0px;

  span {
    font-size: 1.1rem;
    font-weight: 450;
    margin: 0;
  }

`;

export const CoresDiv = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-around;
  width: 100%;
  /* background-color: cyan; */

  span {
    margin: 0;
  }
`;

export const PaletaCoresDiv = styled.div`
  display: flex;
  flex-wrap: wrap;
  flex-direction: row;
  width: 100%;
  column-gap: 10px;
  row-gap: 5px;
`;

export const TamanhosDiv = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-around;
  display: flex;
  width: 47.5%;

  @media screen and (max-width: 767px){
    width: 100%;
  }
`;

export const PaletaTamanhosDiv = styled.div`
  display: flex;
  flex-wrap: wrap;
  flex-direction: row;
  width: 100%;
  column-gap: 10px;
  row-gap: 5px;
`;

export const QuantidadeButtonDiv = styled.div`
  display: flex;
  align-items: center;
  width: 100%;

  margin-top: 1rem;
  button {
    margin-top: 0px;
  }

  button:first-child {
    margin-left: 0px;
  }

`;
