import styled from 'styled-components';
import { FinalizarCarrinhoDiv } from '../../pages/Carrinho/styles';

interface IContainer {
  isOpen: boolean;
}

export const Container = styled.div<IContainer>`
  display: flex;
  flex-direction: column;
  align-items: center;
  position: fixed;
  top: 0;
  ${({ isOpen }) => isOpen ? 'right: 0;' : 'right: -27.5rem;'}
  width: 27.5rem;
  height: 100%;
  background-color: #fff;
  transition: right 0.3s ease-in-out;
  overflow: scroll;
  font-weight: 450;
  z-index: 9999999;
  overflow: auto;
  box-shadow: 0 4px 8px 0 rgba(171, 183, 183,0.2), 0 3px 10px 0 rgba(171, 183, 183,0.5);

  b {
    font-size: 1.2rem;
  }

  hr {
    border-width: 1px;
    color: #eee;
    margin: 1rem 1rem;
    opacity: 0.3;
    width: 90%;
  }

  span {
    font-weight: 450;
  }

  img {
    object-fit: contain;
  }

  @media screen and (max-width: 767px) {
    width: 20rem;
  }
`;

export const CloseDiv = styled.div`
  width: 100%;

  svg {
    margin: 1rem 0px 0px 1rem;
    cursor: pointer;
  }
`;


export const FinalizarCarrinhoDivCustom = styled(FinalizarCarrinhoDiv)`
  width: 100%;

  button {
    color: #fff;

    * {
      color: #fff;
    }
  }
`;
