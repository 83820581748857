import React, { useState, useEffect, useContext } from 'react';
import { toast } from 'react-toastify';
import Slider from 'react-slick';
import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';

import Card from '../Card';

import { CarouselContainer, Container } from './styles';

import api from '../../services/api';
import Context, { IContext } from '../../context/Context';
import semFoto from '../../assets/images/sem-foto.jpg';

interface ProdutoCardProps {
  linkFot: string;
  mer: string;
  codbar: string;
  valVenMin: number;
  parcelamento?: string;
  esgSit?: boolean;
}

export default function ProdutosDestaque() {
  const { configs }: IContext = useContext(Context);

  const [produtos, setProdutos] = useState<ProdutoCardProps[]>([]);
  const [tipoCardImagem, setTipoCardImagem] = useState('');

  async function getProdutosCardDestaque() {
    try {
      const response = await api.get('/mercador/listarProdutosCard?page=0&codtabpre=0&visdes=1&size=60');

      const newProdutos: ProdutoCardProps[] = response.data.content.map((produtos: any) => {
        return {
          linkFot: produtos.linkFot ? 'https://' + produtos.linkFot : semFoto,
          mer: produtos.mer,
          codbar: produtos.codBar,
          valVenMin: produtos.valVenMin,
          esgSit: Boolean(produtos.esgSit)
        };
      });

      setProdutos(newProdutos);

    } catch (error: any) {
      toast.error('Falha ao buscar destaques. ' + error.message);
    }
  }

  useEffect(() => {
    getProdutosCardDestaque();
  }, []);

  useEffect(() => {
    if (configs.length > 0) {
      const [{ val: tipoImagem }] = configs.filter((config: any) => config.con === 'ExiTipImg');

      setTipoCardImagem(tipoImagem.toLowerCase());
    }
  }, [configs]);

  const settings = {
    className: 'center-slider',
    centerMode: true,
    infinite: true,
    centerPadding: '80px',
    slidesToShow: 4,
    swipeToSlide: true,
    speed: 500,
    autoplay: true,
    autoplaySpeed: 2000,
    cssEase: 'linear',
    responsive: [
      {
        breakpoint: 1700,
        settings: {
          slidesToShow: 3,
        }
      },
      {
        breakpoint: 1300,
        settings: {
          slidesToShow: 2,
        }
      },
      {
        breakpoint: 900,
        settings: {
          slidesToShow: 1,
          centerPadding: tipoCardImagem === 'paisagem' ? '85px' : '85px',
        }
      },
      {
        breakpoint: 400,
        settings: {
          slidesToShow: 1,
          centerPadding: '80px',
        }
      }
    ]
  };

  return (
    <Container >
      <p>Nossos Destaques</p>
      <Slider {...settings}>
        {produtos.length > 0 && produtos.map((produto, i) => {
          return (
            <CarouselContainer key={i} tipoCardImagem={tipoCardImagem}>
              <Card
                key={i}
                imageSrc={produto.linkFot}
                nome={produto.mer}
                codbar={produto.codbar}
                preço={produto.valVenMin}
                esgotado={produto.esgSit}
              />
            </CarouselContainer>
          );
        })}
      </Slider>
    </Container>
  );
}
