import React, { useState, useEffect, useContext, useRef } from 'react';
import { Link, useNavigate, useParams } from 'react-router-dom';
import { toast } from 'react-toastify';
import { Carousel } from 'react-responsive-carousel';
import LoadingOverlay from 'react-loading-overlay-ts';
import Cards from 'react-credit-cards-2';
import 'react-credit-cards-2/dist/es/styles-compiled.css';
import ReactModal from 'react-modal';
import { AiOutlineCopy } from 'react-icons/ai';

import {
  Button, Container, Logo,
  MenuParalelo, CheckBoxButtonDiv, SlideContainer,
  WrapInput, ButtonDiv, CarouselContainer,
  TotaisDiv, ProdutoDiv, MultiFreteCustomDiv,
  InputContainer, CupomInputCustom,
  InputButtonDiv
} from './styles';

import Context, { ICarrinho, IConfig, IContext, IEndUsu } from '../../context/Context';
import ProgressBar from '../../components/ProgressBar';
import SelectInput, { ISelectOptions } from '../../components/SelectInput';
import PaymentAccordion from '../../components/PaymentAccordion';

import api from '../../services/api';
import { IMultiFrete } from '../ProdutoDetalhes';
import { cnpjMask, cpfMask, dataExpiracaoCartaoMask, validaCpfCnpj } from '../../utils/ValidaCpfCnpj';
import { BuscaEndereco } from '../../utils/buscaCep';
import { formatCurrency } from '../../utils/formatCurrency';
import { gtmEventPush } from '../../utils/gtmEventPush';
import LogoCielo from '../../assets/images/logoCielo.svg';
import { ICheckoutTransparenteCieloCreditCard, ICheckoutTransparenteCieloPix, IPixPaymentResponse } from '../../types/Cielo';
import { detectarBandeiraCartao } from '../../utils/detectarBandeiraCartao';
import useWindowDimensions from '../../utils/WindowDimensions';
import { IAppUser, IPedidoPayload } from '../../types/PedidoPayload';
import semFoto from '../../assets/images/sem-foto.jpg';
import { ILink, IPagSeguroPayload } from '../../types/PagSeguro';

interface IDadPag {
  numCar: string;
  datExp: string;
  cvc: string;
  nomCom: string;
  cgc: string;
  focus: 'name' | 'number' | 'expiry' | 'cvc' | '' | undefined;
}

export interface ICupom {
  valor: number;
  cod: number;
}

interface IQuaParSit {
  cod: number;
  quapar: number;
  valcar: number;
  dathoralt?: string;
  ativo: number;
}

type IForPagCielo = 'CreditCard' | 'DebitCard' | 'Pix' | '';

export default function FinalizarCarrinho() {
  const navigate = useNavigate();

  const { codped } = useParams();

  const {
    configs, carrinho,
    dadosLogin, setCarrinho,
  }: IContext = useContext(Context);

  const cupomInputRef = useRef<HTMLInputElement>(null);

  const { width } = useWindowDimensions();

  const isMobile = width <= 767;

  const [indexCarousel, setIndexCarousel] = useState<number>(0);
  const [isLoadingPost, setIsLoadingPost] = useState<boolean>(false);

  //Dados do comprador
  const [cgc, setCgc] = useState<string>(dadosLogin?.cgc ?? '');
  const [nom, setNom] = useState<string>(dadosLogin?.raz ?? '');
  const [email, setEmail] = useState<string>(dadosLogin?.username ?? '');
  const [cel, setCel] = useState<string>(dadosLogin?.fon ?? '');
  const [datNas, setDatNas] = useState<string>('');

  //Dados do Pagamento
  const [forPag, setForPag] = useState<IForPagCielo>('');
  const [numPar, setNumPar] = useState<string>('');
  const [totNumPar, setTotNumPar] = useState<ISelectOptions[]>([{ value: '1', text: '1x ' }]);
  const [dadPag, setDadPag] = useState<IDadPag>({
    numCar: '', datExp: '', cvc: '',
    nomCom: '', cgc: '', focus: ''
  });
  const [pixPayment, setPixPayment] = useState<IPixPaymentResponse>({
    qrCodeBase64Image: '',
    qrCodeString: '',
  });
  const lisForPag = {
    CreditCard: 'Cartão de Crédito',
    DebitCard: 'Cartão de Debito',
    Pix: 'PIX'
  };

  //Endereço de entrega
  const [EndUsuPad]: IEndUsu[] = dadosLogin.endUsu.filter((endUsu: IEndUsu) => endUsu.padent === 1);

  const [dadEnt, setDadEnt] = useState<IEndUsu>({
    log: EndUsuPad?.log || '',
    num: EndUsuPad?.num || '',
    comlog: EndUsuPad?.comlog || '',
    bai: EndUsuPad?.bai || '',
    cid: EndUsuPad?.cid || '',
    uf: EndUsuPad?.uf || '',
    codibg: EndUsuPad?.codibg || 0,
    cep: EndUsuPad?.cep || '',
    padent: 1
  });

  //Dados extra da entrega
  const [retLoj, setRetLoj] = useState<boolean>(false);
  const [pagRet, setPagRet] = useState<boolean>(false);
  const [dest, setDest] = useState<string>('');
  const [celDest, setCelDest] = useState<string>('');
  const [datEnt, setDatEnt] = useState<string>('');
  const [horIni, setHorIni] = useState<string>('');
  const [horFin, setHorFin] = useState<string>('');
  const [menCar, setMenCar] = useState<string>('');

  //Carrinho e valores
  const [valFre, setValFre] = useState(0);
  const [mulFre, setMulFre] = useState<IMultiFrete[]>([]);
  const [mulFreSelecionado, setMulFreSelecionado] = useState<IMultiFrete | undefined>();
  const [subTot, setSubTot] = useState(0);
  const [itensCarrinho, setItensCarrinho] = useState<ICarrinho[]>([]);
  const [cupom, setCupom] = useState<ICupom>({
    valor: 0,
    cod: 0
  });

  //config
  const [logoURI, setLogoURI] = useState<string>('');
  const [keyApiGoo, setKeyApiGoo] = useState<string>('0');
  const [usaDadExtEnt, setUsaDadExtEnt] = useState<boolean>(false);
  const [usaOpcPagRetLoj, setUsaOpcPagRetLoj] = useState<boolean>(false);
  const [usaCielo, setUsaCielo] = useState<boolean>(false);
  const [usaPagSeguro, setUsaPagSeguro] = useState<boolean>(false);
  const [quaMaxPar, setQuaMaxPar] = useState<number>(1);
  const [valMinPar, setValMinPar] = useState<number>(1);
  const [valFreGra, setValFreGra] = useState<string>('0');
  const [valMinCom, setValMinCom] = useState<string>('0');
  const [usaCheTra, setUsaCheTra] = useState<boolean>(false);

  //Modal
  const [modalVisible, setModalVisible] = useState<boolean>(false);

  const slides = [
    {
      titulo: 'Dados Pessoais',
      component:
        <SlideContainer>
          <MenuParalelo width={'50%'}>
            <WrapInput>
              <input
                className={nom !== '' ? 'has-val input' : 'input'}
                value={nom}
                onChange={(e) => setNom(e.target.value)}
                enterKeyHint='done'
                maxLength={80}
              />
              <span className='focus-input' data-placeholder='Nome completo*'></span>
            </WrapInput>
            <WrapInput>
              <input
                className={cgc !== '' ? 'has-val input' : 'input'}
                value={cgc}
                onChange={(e) => {
                  if (e.target.value.length > 14) {
                    setCgc(cnpjMask(e.target.value));
                    return;
                  }
                  setCgc(cpfMask(e.target.value));
                }}
                enterKeyHint='done'
                maxLength={18}
                type='tel'
              />
              <span className='focus-input' data-placeholder='CPF/CNPJ*'></span>
            </WrapInput>
          </MenuParalelo>
          <MenuParalelo width={'50%'}>
            <WrapInput>
              <input
                className={email !== '' ? 'has-val input' : 'input'}
                value={email}
                onChange={(e) => setEmail(e.target.value)}
                enterKeyHint='done'
                type='email'
                maxLength={60}
              />
              <span className='focus-input' data-placeholder='Email*'></span>
            </WrapInput>
          </MenuParalelo>
          <MenuParalelo width={'50%'}>
            <WrapInput>
              <input
                className={cel !== '' ? 'has-val input' : 'input'}
                value={cel}
                onChange={(e) => setCel(e.target.value)}
                enterKeyHint='done'
                type='tel'
                maxLength={11}
              />
              <span className='focus-input' data-placeholder='Celular*'></span>
            </WrapInput>
            <WrapInput>
              <input
                className={'has-val input'}
                value={datNas}
                onChange={(e) => setDatNas(e.target.value)}
                onKeyDown={(e) => { if (e.key === 'Tab') e.preventDefault(); }}
                enterKeyHint='done'
                type='date'
                maxLength={14}
              />
              <span className='focus-input' data-placeholder='Data de nascimento'></span>
            </WrapInput>
          </MenuParalelo>
          <Button
            onClick={() => proximoPasso(indexCarousel)}
            onKeyDown={(e) => { if (e.key === 'Tab') e.preventDefault(); }}
          >
            Próximo
          </Button>
          <Link to={'/'}>
            {logoURI && <Logo src={logoURI} alt='Logo da Empresa' />}
          </Link>
        </SlideContainer>,
    },
    {
      titulo: 'Dados Entrega',
      component:
        <SlideContainer>
          <MenuParalelo width={'50%'}>
            <WrapInput>
              <input
                name='cep'
                className={dadEnt.cep !== '' ? 'has-val input' : 'input'}
                value={dadEnt.cep}
                onChange={handleInputChangeDadEntForm}
                onBlur={buscarDadosPorCep}
                maxLength={8}
                enterKeyHint='done'
                type='tel'
              />
              <span className='focus-input' data-placeholder='CEP*'></span>
            </WrapInput>
          </MenuParalelo>
          <MenuParalelo width={'50%'}>
            <WrapInput>
              <input
                name='log'
                className={dadEnt.log !== '' ? 'has-val input' : 'input'}
                value={dadEnt.log}
                onChange={handleInputChangeDadEntForm}
                enterKeyHint='done'
                maxLength={80}
              />
              <span className='focus-input' data-placeholder='Endereço*'></span>
            </WrapInput>
            <WrapInput>
              <input
                name='num'
                className={dadEnt.num !== '' ? 'has-val input' : 'input'}
                value={dadEnt.num}
                onChange={handleInputChangeDadEntForm}
                enterKeyHint='done'
                type='tel'
                maxLength={10}
              />
              <span className='focus-input' data-placeholder='Número*'></span>
            </WrapInput>
          </MenuParalelo>
          <MenuParalelo width={'50%'}>
            <WrapInput>
              <input
                name='comlog'
                className={dadEnt.comlog !== '' ? 'has-val input' : 'input'}
                value={dadEnt.comlog}
                onChange={handleInputChangeDadEntForm}
                enterKeyHint='done'
                maxLength={50}
              />
              <span className='focus-input' data-placeholder='Complemento'></span>
            </WrapInput>
            <WrapInput>
              <input
                name='bai'
                className={dadEnt.bai !== '' ? 'has-val input' : 'input'}
                value={dadEnt.bai}
                onChange={handleInputChangeDadEntForm}
                enterKeyHint='done'
                maxLength={60}
              />
              <span className='focus-input' data-placeholder='Bairro*'></span>
            </WrapInput>
          </MenuParalelo>
          <MenuParalelo width={'50%'}>
            <WrapInput>
              <input
                name='cid'
                className={dadEnt.cid !== '' ? 'has-val input' : 'input'}
                value={dadEnt.cid}
                onChange={handleInputChangeDadEntForm}
                enterKeyHint='done'
                maxLength={60}
              />
              <span className='focus-input' data-placeholder='Cidade*'></span>
            </WrapInput>
            <WrapInput>
              <input
                name='uf'
                className={dadEnt.uf !== '' ? 'has-val input' : 'input'}
                value={dadEnt.uf}
                onChange={handleInputChangeDadEntForm}
                enterKeyHint='done'
                onKeyDown={(e) => { if (e.key === 'Tab') e.preventDefault(); }}
                maxLength={2}
              />
              <span className='focus-input' data-placeholder='Estado*'></span>
            </WrapInput>
          </MenuParalelo>
          {keyApiGoo && valFre > 0 &&
            <span>Taxa de Entrega: {formatCurrency(valFre)}</span>
          }
          {mulFre.length > 0 && <h4>Escolha a transportadora</h4>}
          {mulFre.length > 0 && mulFre.map((mulFre: IMultiFrete, index: number) => (
            <MultiFreteCustomDiv key={index}>
              <input
                type='radio'
                onClick={() => {
                  setValFre(+mulFre.valor);
                  setMulFreSelecionado(mulFre);
                }}
                checked={valFre === +mulFre.valor}
                value={+mulFre.valor}
                readOnly
              />
              <div
                onClick={() => {
                  setValFre(+mulFre.valor);
                  setMulFreSelecionado(mulFre);
                }}
              >
                <img
                  src={mulFre.logoEmpresa}
                  alt={`Logo da Empresa de envio ${mulFre?.empresa}`}
                />
              </div>
              <p>&nbsp;&nbsp;{mulFre.tipo}</p>
              <p>&nbsp;&nbsp;{mulFre.prazo} dias úteis</p>
              <strong>&nbsp;&nbsp;{formatCurrency(+mulFre.valor)}</strong>
            </MultiFreteCustomDiv>
          ))}
          <ButtonDiv>
            <span
              onClick={() => handleVoltarPasso()}
            >
              Voltar
            </span>
            <Button
              onClick={() => proximoPasso(indexCarousel)}
              onKeyDown={(e) => { if (e.key === 'Tab') e.preventDefault(); }}
            >
              {valFreGra !== '0' && subTot >= +valFreGra ? 'Quase lá' : (keyApiGoo && valFre <= 0) || (!keyApiGoo && mulFre.length === 0) ? 'Calcular Frete' : 'Continuar'}
            </Button>
          </ButtonDiv>
          <Link to={'/'}>
            {logoURI && <Logo src={logoURI} alt='Logo da Empresa' />}
          </Link>
        </SlideContainer>,
    },
    {
      titulo: 'Revisão do Pedido',
      component:
        <SlideContainer>
          {itensCarrinho.map((itemCarrinho: ICarrinho, index: number) =>
            <ProdutoDiv key={index}>
              <img
                src={itemCarrinho?.cor?.linkFot}
                title={itemCarrinho.cor.padmer}
                alt={`Imagem do produto ${itemCarrinho?.mer}`}
              />
              <span>{itemCarrinho?.quantidade}x {itemCarrinho?.mer}{' '}{itemCarrinho.codtam}</span>
              <span>{formatCurrency(itemCarrinho?.valor)}</span>
              <b>{formatCurrency(+(itemCarrinho?.quantidade) * itemCarrinho?.valor)}</b>
            </ProdutoDiv>
          )}
          <TotaisDiv>
            <span>
              SubTotal: {formatCurrency(subTot)}
            </span>
            <span>
              Frete: {retLoj ? 'grátis' : formatCurrency(valFre)}
            </span>
            <span>
              Desconto: {formatCurrency(cupom.valor)}
            </span>
            {usaCheTra &&
              <span>
                Pagamento: {forPag ? lisForPag[forPag] : ''}
              </span>
            }
            <b>
              Total: {retLoj ? formatCurrency(subTot - cupom.valor) : formatCurrency(subTot + valFre - cupom.valor)}
            </b>
          </TotaisDiv>
          <br />
          <ValorMinimoCompraCaption />
          <ButtonDiv>
            <span onClick={() => handleVoltarPasso()}>Voltar</span>
            <Button
              onClick={postPedido}
              onKeyDown={(e) => { if (e.key === 'Tab') e.preventDefault(); }}
              disabled={isLoadingPost || (!retLoj && !(valFreGra !== '0' && subTot >= +valFreGra) && valFre <= 0) || (subTot < +valMinCom)}
            >
              Concluir Pagamento
            </Button>
          </ButtonDiv>
          <Link to={'/'}>
            {logoURI && <Logo src={logoURI} alt='Logo da Empresa' />}
          </Link>
        </SlideContainer>,
    }
  ];

  //Caso use checkout transparente será acrescentado o passo dados de pagamento
  if (usaCheTra) {
    const paymentOptions = [
      {
        title: 'PIX',
        forPag: 'Pix',
        iconName: 'FaPix',
        content: (<></>),
      },
      {
        title: 'Cartão de Crédito',
        forPag: 'CreditCard',
        iconName: 'FaCreditCard',
        content: (
          <>
            <Cards
              number={dadPag.numCar}
              expiry={dadPag.datExp}
              cvc={dadPag.cvc}
              name={dadPag.nomCom}
              focused={dadPag.focus}
              placeholders={{
                name: 'NOME DO TITULAR'
              }}
              locale={
                { valid: 'Validade' }
              }
            />
            <br />
            {usaCielo && <img src={LogoCielo} height='20' width='20' alt='Logo Cielo' />}
            <br />
            <MenuParalelo width={'100%'}>
              <WrapInput>
                <input
                  name={'numCar'}
                  className={dadPag.numCar !== '' ? 'has-val input' : 'input'}
                  value={dadPag.numCar}
                  onChange={handleInputChangeDadPagForm}
                  onFocus={handleInputFocus}
                  enterKeyHint='done'
                  type=''
                  maxLength={19}
                  autoComplete={'cc-number'}
                  inputMode={'numeric'}
                  pattern='[0-9\s]{13,19}'
                />
                <span className='focus-input' data-placeholder='Número do Cartão*'></span>
              </WrapInput>
            </MenuParalelo>
            <MenuParalelo width={'100%'}>
              <WrapInput>
                <input
                  name={'nomCom'}
                  className={dadPag.nomCom !== '' ? 'has-val input' : 'input'}
                  value={dadPag.nomCom}
                  onChange={handleInputChangeDadPagForm}
                  onFocus={handleInputFocus}
                  enterKeyHint='done'
                />
                <span className='focus-input' data-placeholder='Nome do Titular*'></span>
              </WrapInput>
              <WrapInput>
                <input
                  name={'cgc'}
                  className={dadPag.cgc !== '' ? 'has-val input' : 'input'}
                  value={dadPag.cgc}
                  onChange={handleInputChangeDadPagForm}
                  onFocus={handleInputFocus}
                  enterKeyHint='done'
                  type='tel'
                  maxLength={18}
                />
                <span className='focus-input' data-placeholder='CPF do Titular*'></span>
              </WrapInput>
            </MenuParalelo>
            <MenuParalelo width={'100%'}>
              <WrapInput>
                <input
                  name={'datExp'}
                  className={dadPag.datExp !== '' ? 'has-val input' : 'input'}
                  value={dadPag.datExp}
                  onChange={handleInputChangeDadPagForm}
                  enterKeyHint='done'
                  type='tel'
                  onFocus={handleInputFocus}
                  maxLength={5}
                />
                <span className='focus-input' data-placeholder='Data de Validade*'></span>
              </WrapInput>
              <WrapInput>
                <input
                  name={'cvc'}
                  className={dadPag.cvc !== '' ? 'has-val input' : 'input'}
                  value={dadPag.cvc}
                  onChange={handleInputChangeDadPagForm}
                  enterKeyHint='done'
                  type='tel'
                  maxLength={3}
                  onFocus={handleInputFocus}
                />
                <span className='focus-input' data-placeholder='CVC*'></span>
              </WrapInput>
            </MenuParalelo>
            <MenuParalelo width={'100%'}>
              <SelectInput
                value={numPar}
                onChange={setNumPar}
                options={totNumPar.map((parcela: ISelectOptions) => ({ value: parcela.value, text: parcela.text + formatCurrency((subTot + valFre - cupom.valor) / +parcela.value) + ' Sem Juros' }))}
                defaultText='Escolha o Parcelamento'
                width={'100%'}
                background={'#fff'}
              />
            </MenuParalelo>
          </>
        ),
      },
      // Débito desabilitado no momento
      // {
      //   title: 'Cartão de Débito',
      //   forPag: 'debitCard',
      //   iconName: 'FaCreditCard',
      //   content: (
      //     <>
      //       <Cards
      //         number={dadPag.numCar}
      //         expiry={dadPag.datExp}
      //         cvc={dadPag.cvc}
      //         name={dadPag.nomCom}
      //         focused={dadPag.focus}
      //         placeholders={{
      //           name: 'NOME DO TITULAR'
      //         }}
      //         locale={
      //           { valid: 'Validade' }
      //         }
      //       />
      //       <br />
      //       {usaCielo && <img src={LogoCielo} height='20' width='20' alt='Logo Cielo' />}
      //       <br />
      //       <MenuParalelo>
      //         <WrapInput>
      //           <input
      //             name={'numCar'}
      //             className={dadPag.numCar !== '' ? 'has-val input' : 'input'}
      //             value={dadPag.numCar}
      //             onChange={handleInputChangeDadPagForm}
      //             onFocus={handleInputFocus}
      //             enterKeyHint='done'
      //             type=''
      //             maxLength={19}
      //             autoComplete={'cc-number'}
      //             inputMode={'numeric'}
      //             pattern='[0-9\s]{13,19}'
      //           />
      //           <span className='focus-input' data-placeholder='Número do Cartão*'></span>
      //         </WrapInput>
      //       </MenuParalelo>
      //       <MenuParalelo>
      //         <WrapInput>
      //           <input
      //             name={'nomCom'}
      //             className={dadPag.nomCom !== '' ? 'has-val input' : 'input'}
      //             value={dadPag.nomCom}
      //             onChange={handleInputChangeDadPagForm}
      //             onFocus={handleInputFocus}
      //             enterKeyHint='done'
      //           />
      //           <span className='focus-input' data-placeholder='Nome do Titular*'></span>
      //         </WrapInput>
      //         <WrapInput>
      //           <input
      //             name={'cgc'}
      //             className={dadPag.cgc !== '' ? 'has-val input' : 'input'}
      //             value={dadPag.cgc}
      //             onChange={handleInputChangeDadPagForm}
      //             onFocus={handleInputFocus}
      //             enterKeyHint='done'
      //             type='tel'
      //             maxLength={18}
      //           />
      //           <span className='focus-input' data-placeholder='CPF do Titular*'></span>
      //         </WrapInput>
      //       </MenuParalelo>
      //       <MenuParalelo>
      //         <WrapInput>
      //           <input
      //             name={'datExp'}
      //             className={dadPag.datExp !== '' ? 'has-val input' : 'input'}
      //             value={dadPag.datExp}
      //             onChange={handleInputChangeDadPagForm}
      //             enterKeyHint='done'
      //             type='tel'
      //             onFocus={handleInputFocus}
      //             maxLength={5}
      //           />
      //           <span className='focus-input' data-placeholder='Data de Validade*'></span>
      //         </WrapInput>
      //         <WrapInput>
      //           <input
      //             name={'cvc'}
      //             className={dadPag.cvc !== '' ? 'has-val input' : 'input'}
      //             value={dadPag.cvc}
      //             onChange={handleInputChangeDadPagForm}
      //             enterKeyHint='done'
      //             type='tel'
      //             maxLength={3}
      //             onFocus={handleInputFocus}
      //           />
      //           <span className='focus-input' data-placeholder='CVC*'></span>
      //         </WrapInput>
      //       </MenuParalelo>
      //     </>
      //   ),
      // }
    ];

    const passoCheckoutTransparente = {
      titulo: 'Dados Pagamento',
      component:
        <SlideContainer>
          Escolha a Forma de Pagamento:
          <PaymentAccordion
            options={paymentOptions}
            width={isMobile ? '90%' : '40%'}
            setForPag={setForPag}
            forPagSelecionado={forPag}
          />
          <br />
          <InputContainer>
            <label>Cupom de Desconto:</label>
            <InputButtonDiv>
              <CupomInputCustom
                ref={cupomInputRef}
                disabled={subTot <= 0 || cupom.valor !== 0 || cupom.cod < 0}
                onKeyDown={cupomInputKeyPress}
              />
              <button
                onClick={() => {
                  if (cupom.valor === 0) {
                    validaCupom(cupomInputRef.current!.value);
                    return;
                  }

                  cupomInputRef.current!.value = '';
                  setCupom({ valor: 0, cod: 0 });
                }}
                disabled={cupom.cod < 0}
              >
                {cupom.valor === 0 ? 'Aplicar' : 'Remover'}
              </button>
            </InputButtonDiv>
          </InputContainer>
          <TotaisDiv>
            <span>
              SubTotal: {formatCurrency(subTot)}
            </span>
            <span>
              Frete: {retLoj ? 'grátis' : formatCurrency(valFre)}
            </span>
            <span>
              Desconto: {formatCurrency(cupom.valor)}
            </span>
            <span>
              Pagamento: {forPag ? lisForPag[forPag] : ''}
            </span>
            <b>
              Total: {retLoj ? formatCurrency(subTot - cupom.valor) : formatCurrency(subTot + valFre - cupom.valor)}
            </b>
          </TotaisDiv>
          <ButtonDiv>
            <span onClick={() => handleVoltarPasso()}>Voltar</span>
            <Button
              onClick={() => proximoPasso(indexCarousel)}
              onKeyDown={(e) => { if (e.key === 'Tab') e.preventDefault(); }}
            >
              Prosseguir
            </Button>
          </ButtonDiv>
          <Link to={'/'}>
            {logoURI && <Logo src={logoURI} alt='Logo da Empresa' />}
          </Link>
        </SlideContainer >,
    };
    slides.splice(slides.length - 1, 0, passoCheckoutTransparente);
  }

  //Se usar dados extras de entra é acrescentado um novo passo no slide, exemplo de cliente que usa: Floricultura Viverde
  if (usaDadExtEnt) {
    const passoDadosExtras = {
      titulo: 'Dados Extras',
      component:
        <SlideContainer>
          {usaOpcPagRetLoj && <CheckBoxButtonDiv>
            <input
              type='checkbox'
              value='false'
              onChange={() => {
                setRetLoj(!retLoj);
                setPagRet(false);
              }}
            />  Retirar na loja
            {retLoj &&
              <>
                <input
                  type='checkbox'
                  value='false'
                  onChange={() =>
                    setPagRet(!pagRet)
                  }
                />
                Pagar na Retirada
              </>
            }
          </CheckBoxButtonDiv>}
          <WrapInput>
            <input
              className={dest !== '' ? 'has-val input' : 'input'}
              value={dest}
              onChange={(e) => setDest(e.target.value)}
              enterKeyHint='done'
              maxLength={50}
            />
            <span className='focus-input' data-placeholder='Quem vai receber?*'></span>
          </WrapInput>
          <MenuParalelo>
            <WrapInput>
              <input
                className={celDest !== '' ? 'has-val input' : 'input'}
                value={celDest}
                onChange={(e) => setCelDest(e.target.value)}
                enterKeyHint='done'
                type='tel'
                maxLength={11}
              />
              <span className='focus-input' data-placeholder='Celular*'></span>
            </WrapInput>
            <WrapInput>
              <input
                className={'has-val input'}
                value={datEnt}
                onChange={(e) => setDatEnt(e.target.value)}
                enterKeyHint='done'
                type='date'
              />
              <span className='focus-input' data-placeholder='Data da Entrega*'></span>
            </WrapInput>
          </MenuParalelo>
          <MenuParalelo>
            <WrapInput>
              <input
                className={'has-val input'}
                value={horIni}
                onChange={(e) => setHorIni(e.target.value)}
                onBlur={(e) => {
                  if (horIni === '') return;
                  if (e.target.value < '08:00') {
                    toast.warn('Horário inicial de entrega inválido');
                    setHorIni('');
                    return;
                  }
                  if (e.target.value >= '18:00') {
                    toast.warn('Horário inicial de entrega inválido');
                    setHorIni('');
                    return;
                  }
                }}
                enterKeyHint='done'
                type='time'
              />
              <span className='focus-input' data-placeholder='Hora inicial*'></span>
            </WrapInput>
            <WrapInput>
              <input
                className={'has-val input'}
                value={horFin}
                onChange={(e) => setHorFin(e.target.value)}
                onBlur={(e) => {
                  if (horFin === '') return;
                  if (e.target.value > '18:00') {
                    toast.warn('Horário final de entrega inválido');
                    setHorFin('');
                    return;
                  }
                  if (e.target.value <= '08:00') {
                    toast.warn('Horário final de entrega inválido');
                    setHorFin('');
                    return;
                  }
                }}
                enterKeyHint='done'
                type='time'
              />
              <span className='focus-input' data-placeholder='Hora final*'></span>
            </WrapInput>
          </MenuParalelo>
          <WrapInput>
            <input
              className={menCar !== '' ? 'has-val input' : 'input'}
              value={menCar}
              onChange={(e) => setMenCar(e.target.value)}
              enterKeyHint='done'
            />
            <span className='focus-input' data-placeholder='Mensagem para o Cartão'></span>
          </WrapInput>
          {!retLoj && <span>Taxa de Entrega: {formatCurrency(valFre)}</span>}
          <ButtonDiv>
            <span onClick={() => handleVoltarPasso()}>Voltar</span>
            <Button
              onClick={() => proximoPasso(indexCarousel)}
              onKeyDown={(e) => { if (e.key === 'Tab') e.preventDefault(); }}
            >
              Prosseguir
            </Button>
          </ButtonDiv>
          <Link to={'/'}>
            {logoURI && <Logo src={logoURI} alt='Logo da Empresa' />}
          </Link>
        </SlideContainer>,
    };
    slides.splice(slides.length - 1, 0, passoDadosExtras);
  }

  function handleVoltarPasso() {
    if (!codped || slides.length === indexCarousel + 1) {
      setIndexCarousel((prev) => prev - 1);
      return;
    }

    toast.warning('Para alterar Dados Pessoais e Dados da Entrega, lance o pedido novamente.');
  }

  function proximoPasso(passo: number) {
    const cupomSalvo = localStorage.getItem('@Cupom');

    switch (passo) {
      case 0:
        if (!validaCpfCnpj(cgc)) {
          toast.error('CPF/CNPJ inválido');
          return;
        }
        if (!nom) {
          toast.error('Nome inválido');
          return;
        }
        if (!email) {
          toast.error('Email inválido');
          return;
        }
        if (cel.length < 8) {
          toast.error('Celular inválido');
          return;
        }

        setIndexCarousel((passo) => passo + 1);
        setTimeout(() => {
          window.scrollTo({ top: 0, behavior: 'smooth' });
        }, 100);
        break;
      case 1:
        if (dadEnt.cep.length < 8) {
          toast.error('Cep inválido');
          return;
        }
        if (!dadEnt.log) {
          toast.error('Endereço inválido');
          return;
        }
        if (!dadEnt.num) {
          toast.error('Número inválido');
          return;
        }
        if (!dadEnt.bai) {
          toast.error('Bairro inválido');
          return;
        }
        if (!dadEnt.cid) {
          toast.error('Cidade inválida');
          return;
        }
        if (!dadEnt.uf) {
          toast.error('Estado inválido');
          return;
        }

        getQuantidadeParcelas(usaCheTra, subTot, valFre);

        if (valFreGra !== '0' && subTot >= +valFreGra) {
          if (cupomSalvo) {
            cupomInputRef.current!.value = cupomSalvo;

            validaCupom(cupomSalvo);
          }

          setIndexCarousel((passo) => passo + 1);
          setTimeout(() => {
            window.scrollTo({ top: 0, behavior: 'smooth' });
          }, 100);
          return;
        }

        if (valFre === 0) {
          calcularFrete();
        }

        if ((mulFre.length > 0 && valFre > 0) || (keyApiGoo && valFre > 0)) {
          if (cupomSalvo) {
            cupomInputRef.current!.value = cupomSalvo;

            validaCupom(cupomSalvo);
          }

          setIndexCarousel((passo) => passo + 1);
          setTimeout(() => {
            window.scrollTo({ top: 0, behavior: 'smooth' });
          }, 100);
        }

        break;
      case 2:
        if (usaDadExtEnt) {
          if (!dest) {
            toast.error('Destinatário inválido');
            return;
          }
          if (celDest.length < 8) {
            toast.error('Celular do Destinatário inválido');
            return;
          }
          if (!datEnt) {
            toast.error('Data de entrega inválida');
            return;
          }
          if (!horIni) {
            toast.error('Hora inicial de entrega inválida');
            return;
          }
          if (!horFin) {
            toast.error('Hora final de entrega inválida');
            return;
          }
        }

        if (usaCheTra) {
          if (!forPag) {
            toast.error('Selecione uma forma de pagamento');
            return;
          }
          if (forPag !== 'Pix') {
            if (!dadPag.numCar || dadPag.numCar.length < 16) {
              toast.error('Número do Cartão inválido');
              return;
            }
            if (!dadPag.nomCom) {
              toast.error('Nome do Cartão inválido');
              return;
            }
            if (!validaCpfCnpj(dadPag.cgc)) {
              toast.error('CPF/CNPJ inválido');
              return;
            }
            if (!dadPag.datExp || dadPag.datExp.length !== 5) {
              toast.error('Data de validade do Cartão inválida');
              return;
            }
            if (!dadPag.cvc || dadPag.cvc.length !== 3) {
              toast.error('CVC do Cartão inválido');
              return;
            }
            if (!numPar) {
              toast.error('Selecione a quantidade parcelas');
              return;
            }
          }
        }

        setIndexCarousel((passo) => passo + 1);
        setTimeout(() => {
          window.scrollTo({ top: 0, behavior: 'smooth' });
        }, 100);
        break;
      default:
        break;
    }
  }

  function ValorMinimoCompraCaption() {
    if (valMinCom !== '0' && subTot < +valMinCom) {
      const falta = +valMinCom - subTot;

      return (
        <p style={{ width: '100%', color: 'red', textAlign: 'center' }}>
          Valor minimo de compra: {formatCurrency(+valMinCom)} em produtos. Adicione mais {formatCurrency(falta)}
        </p>
      );
    }

    return (
      <></>
    );
  }

  async function calcularFrete() {
    try {
      setIsLoadingPost(true);

      if (valFreGra !== '0' && subTot >= +valFreGra) {
        toast.success('Frete grátis aplicado ✨');
        setValFre(0);
        return;
      }

      if (keyApiGoo !== '0') {
        const destino = `${dadEnt.log}, ${dadEnt.num} - ${dadEnt.bai}, ${dadEnt.cid} - ${dadEnt.uf}, ${dadEnt.cep}`;

        const response = await api.get(`/pedidos/CalcularDistanciaParaEntregar?destino=${destino}`);

        if (response.status === 200) {
          if (response.data.length > 0) {
            setValFre(+(response.data[0].valor.replace(',', '.')));
            return;
          }
          return;
        }
      }

      const itens = carrinho.map((itemCarrinho: ICarrinho) => ({ codmer: itemCarrinho.codmer.toString(), qua: +itemCarrinho.quantidade }));

      const payload = {
        cep: dadEnt.cep,
        itens
      };

      const response = await api.post('/mercador/calcularParaFrete', payload);

      if (response.data.includes('Unauthorized')) {
        throw Error('Melhor Envio não autorizado.');
      }

      if (response.status === 200 && Array.isArray(response.data)) {
        setMulFre(response.data);

        if (response.data.length > 0) {
          setValFre(+response.data[0].valor);
          setMulFreSelecionado(response.data[0]);
        }
      }

    } catch (error: any) {
      toast.error('Falha ao calcular frete. ' + error.message);
    } finally {
      setIsLoadingPost(false);
    }
  }

  async function validaCupom(cupDes: string) {
    try {
      if (!cupDes) return;

      const response = await api.get('/cupdes', {
        params: {
          cupdes: cupDes,
          codusu: dadosLogin.id,
          valcar: subTot
        }
      });

      if (response.status === 200) {
        if (response.data?.status !== 0) {
          localStorage.removeItem('@Cupom');
          toast.warning(response.data?.mensagem || 'Cupom inválido ou expirado');
          return;
        }

        let valorCupom = response.data.valor;

        if (response.data.tipoValor === 'P') {
          valorCupom = subTot * (response.data.valor / 100);
        }

        //Caso valor de desconto do cupom for maior que o valor total do carrinho
        if (valorCupom >= subTot) {
          valorCupom = subTot;
        }

        setCupom({ valor: valorCupom, cod: response.data.cod });
        toast.success('Cupom de desconto aplicado');
        return;
      }

      if (cupom.valor > 0) {
        setCupom({ valor: 0, cod: 0 });
      }

      localStorage.removeItem('@Cupom');
      toast.warning('Cupom inválido ou expirado');
    } catch (error: any) {
      toast.error('Falha ao aplicar cupom. ' + error.message);
      localStorage.removeItem('@Cupom');
      setCupom({ valor: 0, cod: 0 });
    }
  }

  function cupomInputKeyPress(event: React.KeyboardEvent<HTMLInputElement>) {
    if (event.key === 'Enter') {
      if (event.target instanceof HTMLInputElement) {
        const value = event.target.value;

        validaCupom(value);
      }
    }
  }

  async function postPedido() {
    try {
      if ((!retLoj && valFre <= 0 && +valFreGra === 0)) {
        toast.error('Frete inválido, não foi possível calcular valor de frete.');
        return;
      }

      if (valFre === 0 && subTot < +valFreGra) {
        toast.error('Frete inválido. Valor minimo para frete grátis: ' + formatCurrency(+valFreGra));
        return;
      }

      if (usaCielo && usaCheTra && !pagRet && (+numPar > 1 && (subTot + valFre - cupom.valor) < 10)) {
        toast.error('Valor minimo de compra para parcelamento: ' + formatCurrency(10));
        return;
      }

      setIsLoadingPost(true);

      let response: any;

      const date = new Date();
      const dathor = date.toISOString();

      const itensPedido = itensCarrinho.map((itemCarrinho: ICarrinho) => {
        return { obs: '', qua: itemCarrinho.quantidade, valuni: itemCarrinho.valor, mercador: { cod: itemCarrinho.codmer, mer: itemCarrinho.mer } };
      });

      const pedidoPayload: IPedidoPayload = {
        cod: '', codcat: null, dathor: dathor,
        forpag: forPag, obs: '', sta: pagRet || (!usaCielo && !usaPagSeguro) ? 'Pagamento Futuro' : 'Pendente',
        valfre: retLoj ? 0 : valFre, valdes: cupom.valor, valpro: +(valFre + subTot - cupom.valor).toFixed(2),
        retloj: +retLoj, pagloj: +pagRet, quevairec: dest,
        datent: datEnt, horini: horIni, horfin: horFin,
        men: menCar, telquevairec: celDest, itensPedido: itensPedido,
        appuser: {
          id: dadosLogin.id
        },
        ...(cupom.cod > 0 && { cupdes: { cod: cupom.cod } }),
        ...(mulFreSelecionado?.codigo && { opcfre: { cod: mulFreSelecionado.codigo } })
      };

      if (codped) {
        pedidoPayload.cod = codped;

        response = await api.put('/pedidos/alterarPedido', pedidoPayload, {
          headers: {
            'Content-Type': 'application/json',
          }
        });
      }

      if (!codped) {
        response = await api.post('/pedidos/salvarPed', pedidoPayload, {
          headers: {
            'Content-Type': 'application/json',
          }
        });

        if (response.status === 201) {
          navigate(`/finalizarCarrinho/${response.data.cod}`);
        }
      }

      if (response?.status === 201 || response?.status === 200) {
        // pedidoPayload.cod = response.data.cod;

        const endUsu: IEndUsu = {
          cep: dadEnt.cep.replace(/[^0-9]/g, ''),
          log: dadEnt.log.normalize('NFD'),
          num: dadEnt.num,
          bai: dadEnt.bai.normalize('NFD'),
          cid: dadEnt.cid.normalize('NFD'),
          uf: dadEnt.uf,
          comlog: dadEnt.comlog!.normalize('NFD'),
          codibg: dadEnt.codibg,
          appuser: {
            id: dadosLogin.id
          },
          padent: 1
        };

        const dadosCliente: IAppUser = {
          id: dadosLogin.id,
          username: dadosLogin.username,
          password: dadosLogin.password,
          fon: cel,
          cel: cel,
          ema: email,
          cgc: cgc,
          // insest: order.usuario.insest,
          fan: nom,
          raz: nom,
          datnas: datNas,
          // codtabpre: 0,
          tipusu: 'comum',
          autverprosit: dadosLogin.autverprosit
        };

        await api.put('/usuarios/atualizar', dadosCliente, {
          headers: {
            'Content-Type': 'application/json'
          }
        });

        await api.post('/endusus/salvar', endUsu, {
          headers: {
            'Content-Type': 'application/json'
          }
        });

        if (usaCielo && usaCheTra && !pagRet) {
          const expirationDate = dadPag.datExp.split('/');

          let cieloPayload: ICheckoutTransparenteCieloCreditCard | ICheckoutTransparenteCieloPix | undefined = undefined;

          if (forPag === 'Pix') {
            cieloPayload = {
              merchantOrderId: response.data?.cod || codped,
              customer: {
                name: nom,
                identity: cgc.replace(/\D/g, ''),
                identityType: cgc.replace(/\D/g, '').length === 11 ? 'CPF' : 'CNPJ',
              },
              payment: {
                type: 'Pix',
                amount: dadosLogin.username === 'gustavotiburcio23@gmail.com' ? 100 : Math.ceil((subTot + valFre - cupom.valor) * 100)
              }
            };
          }

          if (forPag === 'CreditCard') {
            cieloPayload = {
              merchantOrderId: response.data?.cod || codped,
              customer: {
                name: nom,
                identity: cgc.replace(/\D/g, ''),
                identityType: cgc.replace(/\D/g, '').length === 11 ? 'CPF' : 'CNPJ',
                email: email,
                birthdate: '',
                address: {
                  street: endUsu.log,
                  number: endUsu.num,
                  complement: endUsu.comlog || '',
                  neighborhood: endUsu.bai,
                  zipCode: endUsu.cep,
                  city: endUsu.cid,
                  state: endUsu.uf,
                  country: 'BRA'
                },
                billing: {
                  street: endUsu.log,
                  number: endUsu.num,
                  complement: endUsu.comlog || '',
                  neighborhood: endUsu.bai,
                  zipCode: endUsu.cep,
                  city: endUsu.cid,
                  state: endUsu.uf,
                  country: 'BRA'
                },
                deliveryAddress: {
                  street: endUsu.log,
                  number: endUsu.num,
                  complement: endUsu.comlog || '',
                  neighborhood: endUsu.bai,
                  zipCode: endUsu.cep,
                  city: endUsu.cid,
                  state: endUsu.uf,
                  country: 'BRA'
                }
              },
              payment: {
                serviceTaxAmount: 0,
                installments: +numPar,
                recurrent: false,
                softDescriptor: import.meta.env.VITE_TITLE,
                creditCard: {
                  cardNumber: dadPag.numCar.replaceAll(' ', ''),
                  holder: dadPag.nomCom,
                  expirationDate: expirationDate[0] + '/20' + expirationDate[1],
                  securityCode: dadPag.cvc,
                  brand: detectarBandeiraCartao(dadPag.numCar)
                },
                type: 'CreditCard',
                amount: dadosLogin.username === 'gustavotiburcio23@gmail.com' ? 800 : Math.ceil((subTot + valFre - cupom.valor) * 100),
                capture: true
              }
            };
          }

          // if (forPag === 'DebitCard') {
          //...
          // }

          const responseCielo = await api.post('/pedidos/checkoutCielo', cieloPayload, {
            headers: {
              'Content-Type': 'application/json',
            }
          });

          if (responseCielo.status === 201 && (responseCielo.data.Payment.ReturnMessage === 'Transacao autorizada' || responseCielo.data.Payment.ReturnMessage === 'Transacao capturada com sucesso')) {
            gtmEventPush('purchase', {
              ecommerce: {
                currency: 'BRL',
                transaction_id: response.data?.cod || codped,
                tax: 0,
                shipping: valFre,
                coupon: cupomInputRef?.current?.value || '',
                value: carrinho.reduce(
                  (accumulator: any, currentValue: any) => accumulator + currentValue?.valor * +currentValue?.quantidade,
                  0),
                items: carrinho.map((item: ICarrinho) => ({
                  item_id: item.codmer,
                  item_name: item.mer,
                  sku: item.codbar,
                  price: item.valor,
                  item_category: '',
                  id: item.codmer,
                  quantity: +item.quantidade
                }))
              }
            });

            setCarrinho([]);
            navigate('/pedidoFinalizado');
            return;
          }

          if (responseCielo.status === 201 && responseCielo.data.Payment.ReturnMessage === 'Pix gerado com sucesso') {
            gtmEventPush('purchase', {
              ecommerce: {
                currency: 'BRL',
                transaction_id: response.data?.cod || codped,
                tax: 0,
                shipping: valFre,
                coupon: cupomInputRef?.current?.value || '',
                value: carrinho.reduce(
                  (accumulator: number, currentValue: ICarrinho) => accumulator + currentValue?.valor * +currentValue?.quantidade,
                  0),
                items: carrinho.map((item: ICarrinho) => ({
                  item_id: item.codmer,
                  item_name: item.mer,
                  sku: item.codbar,
                  price: item.valor,
                  item_category: '',
                  id: item.codmer,
                  quantity: +item.quantidade
                }))
              }
            });

            if (!responseCielo.data?.Payment?.QrCodeBase64Image) {
              throw Error('Falha ao gerar QrCode, tente novamente');
            }

            setPixPayment({
              qrCodeBase64Image: responseCielo.data?.Payment?.QrCodeBase64Image || '',
              qrCodeString: responseCielo.data?.Payment?.QrCodeString || '',
            });
            setModalVisible(true);

            const verificaPixInterval = setInterval(async () => {
              const responsePedido = await api.get(`/pedidos/listarPedidoPorCliente?nome=${dadosLogin.username}&cod=${response.data?.cod || codped}`);

              if (responsePedido.status === 200 && responsePedido.data[0].status === 'Pago') {
                // Finalizar só quando reconhecer pagamento do pix
                clearInterval(verificaPixInterval);
                setCarrinho([]);
                navigate('/pedidoFinalizado');
                return;
              }
            }, 5000);

            return;
          }

          if (responseCielo.status === 201 && responseCielo.data.Payment.ReturnMessage === 'Autorizacao negada') {
            throw Error('Autorização negada, verifique o cartão e tente novamente.');
          }

          throw Error('Falha ao enviar pedido pra Cielo.');
        }

        if (usaCielo && !pagRet) {
          let shipping: any = { type: 'WithoutShippingPickUp' };
          const maximoDeParcelas = +((subTot + valFre - cupom.valor) / quaMaxPar) < valMinPar ? 1 : +quaMaxPar;

          const itemsCielo = itensCarrinho.map((item: any) => {
            return {
              name: item.mer,
              quantity: item.quantidade,
              unitPrice: (item.valor * 100),
              type: 'Asset'
            };
          });

          if ((pedidoPayload.valfre !== 0 && !retLoj) || (pedidoPayload.valfre === 0 && (subTot - cupom.valor) > +valFreGra)) {
            shipping = {
              type: 'FixedAmount',
              targetZipCode: dadEnt.cep.replace(/[^0-9]/g, ''),
              services: [{
                name: 'Entrega Padrao',
                price: (pedidoPayload.valfre * 100),
              }],
              address: {
                street: dadEnt.log.normalize('NFD'),
                number: dadEnt.num,
                complement: dadEnt.comlog!.normalize('NFD'),
                district: dadEnt.bai.normalize('NFD'),
                city: dadEnt.cid.normalize('NFD'),
                state: dadEnt.uf
              }
            };
          }

          const cieloPayload = {
            'orderNumber': response.data?.cod || codped,
            'cart': {
              'discount': {
                'type': 'Amount',
                'value': cupom.valor * 100,
              },
              'items': itemsCielo,
            },
            'shipping': shipping,
            'payment': {
              'installments': maximoDeParcelas,
              'maxNumberOfInstallments': maximoDeParcelas,
            },
            'customer': {
              'identity': cgc.replace(/[^0-9]/g, ''),
              'fullName': nom,
              'email': email,
              'phone': cel.replace(/[^0-9]/g, ''),
            },
          };

          const responseCielo = await api.post('/pedidos/recebeEEnviaDadosCielo', cieloPayload, {
            headers: {
              'Content-Type': 'application/json',
            }
          });

          if (responseCielo.status === 200) {
            gtmEventPush('purchase', {
              ecommerce: {
                currency: 'BRL',
                transaction_id: response.data?.cod || codped,
                tax: 0,
                shipping: valFre,
                coupon: cupomInputRef?.current?.value || '',
                value: carrinho.reduce(
                  (accumulator: any, currentValue: any) => accumulator + currentValue?.valor * +currentValue?.quantidade,
                  0),
                items: carrinho.map((item: ICarrinho) => ({
                  item_id: item.codmer,
                  item_name: item.mer,
                  sku: item.codbar,
                  price: item.valor,
                  item_category: '',
                  id: item.codmer,
                  quantity: +item.quantidade
                }))
              }
            });

            pedidoPayload.cod = response.data?.cod || codped;
            pedidoPayload.obs = `Pagamento disponível em ${responseCielo.data.settings.checkoutUrl}`;

            await api.put('/pedidos/alterarPedido', pedidoPayload, {
              headers: {
                'Content-Type': 'application/json',
              }
            });
            setCarrinho([]);
            window.location.href = responseCielo.data.settings.checkoutUrl;
            return;
          }
          throw Error('Falha ao enviar pedido pra cielo');
        }

        if (usaPagSeguro && !pagRet) {
          const maximoDeParcelas = +((subTot + valFre - cupom.valor) / quaMaxPar) < valMinPar ? 1 : +quaMaxPar;

          let numCelTratado = cel.replace(/[^0-9]/g, '');

          if (numCelTratado.length > 9) {
            numCelTratado = numCelTratado.slice(-9);
          }
          if (!numCelTratado.startsWith('9')) {
            numCelTratado = `9${numCelTratado}`;
          }

          const pagSeguroPayload: IPagSeguroPayload = {
            reference_id: response.data?.cod || codped,
            expiration_date: new Date((+new Date) + 2592000000).toISOString(), // 30 dias
            additional_amount: 0,
            discount_amount: cupom.valor * 100,
            customer: {
              name: nom,
              email: email,
              tax_id: cgc.replace(/[^0-9]/g, ''),
            },
            items: carrinho.map((item: ICarrinho) => ({
              reference_id: item.codmer.toString(),
              name: item.mer,
              description: item.mer,
              quantity: +item.quantidade,
              unit_amount: dadosLogin.username === 'gustavotiburcio23@gmail.com' ? 100 : (item.valor * 100),
              image_url: item.cor?.linkFot && item.cor.linkFot !== '/src/assets/images/sem-foto.jpg' ? item.cor.linkFot : 'https://www.quitandadelivery.com/images/geral/sem_foto_big.jpg'
            })),
            payment_methods: [
              { type: 'credit_card', brands: ['amex'] },
              { type: 'credit_card', brands: ['elo'] },
              { type: 'credit_card', brands: ['mastercard'] },
              { type: 'credit_card', brands: ['visa'] },
              { type: 'debit_card', brands: ['amex'] },
              { type: 'debit_card', brands: ['elo'] },
              { type: 'debit_card', brands: ['mastercard'] },
              { type: 'debit_card', brands: ['visa'] },
              { type: 'pix' }
            ],
            payment_methods_configs: [
              {
                type: 'credit_card',
                config_options: { option: 'installments_limit', value: maximoDeParcelas.toString() }
              }
            ],
            soft_descriptor: import.meta.env.VITE_TITLE,
            redirect_url: window.location.origin + '/pedidoFinalizado',
            notification_urls: [import.meta.env.VITE_API_URL + '/pedidos/receberNotificacaoPagSeguro'],
            payment_notification_urls: [import.meta.env.VITE_API_URL + '/pedidos/receberNotificacaoPagSeguro'],
          };

          if ((pedidoPayload.valfre !== 0 && !retLoj) || (pedidoPayload.valfre === 0 && (subTot - cupom.valor) > +valFreGra)) {
            pagSeguroPayload.shipping = {
              type: 'FIXED',
              service_type: 'SEDEX',
              address_modifiable: false,
              amount: (pedidoPayload.valfre * 100),
              address: {
                street: dadEnt.log.normalize('NFD'),
                number: dadEnt.num,
                complement: dadEnt.comlog ? dadEnt.comlog!.normalize('NFD') : 'SEM COMPLEMENTO',
                locality: dadEnt.bai.normalize('NFD'),
                city: dadEnt.cid.normalize('NFD'),
                region_code: dadEnt.uf,
                country: 'BRA',
                postal_code: dadEnt.cep
              }
            };
          }

          const responsePagSeguro = await api.post('/pedidos/recebeEEnviaDadosPagSeguro', pagSeguroPayload, {
            headers: {
              'Content-Type': 'application/json',
            }
          });

          if (responsePagSeguro.status === 201) {
            const checkoutUrl = responsePagSeguro.data.links.filter((link: ILink) => link.rel === 'PAY');

            gtmEventPush('purchase', {
              ecommerce: {
                currency: 'BRL',
                transaction_id: response.data?.cod || codped,
                tax: 0,
                shipping: valFre,
                coupon: cupomInputRef?.current?.value || '',
                value: carrinho.reduce(
                  (accumulator: any, currentValue: any) => accumulator + currentValue?.valor * +currentValue?.quantidade,
                  0),
                items: carrinho.map((item: ICarrinho) => ({
                  item_id: item.codmer,
                  item_name: item.mer,
                  sku: item.codbar,
                  price: item.valor,
                  item_category: '',
                  id: item.codmer,
                  quantity: +item.quantidade
                }))
              }
            });

            if (checkoutUrl.length > 0) {
              pedidoPayload.cod = response.data?.cod || codped;
              pedidoPayload.obs = `Pagamento disponível em ${checkoutUrl[0].href}`;

              await api.put('/pedidos/alterarPedido', pedidoPayload, {
                headers: {
                  'Content-Type': 'application/json',
                }
              });

              setCarrinho([]);
              window.location.href = checkoutUrl[0].href;
              return;
            }

            throw new Error('Falha ao redirecionar para PagSeguro.');
          }
        }

        gtmEventPush('purchase', {
          ecommerce: {
            currency: 'BRL',
            transaction_id: response.data?.cod || codped,
            tax: 0,
            shipping: valFre,
            coupon: cupomInputRef?.current?.value || '',
            value: carrinho.reduce(
              (accumulator: any, currentValue: any) => accumulator + currentValue?.valor * +currentValue?.quantidade,
              0),
            items: carrinho.map((item: ICarrinho) => ({
              item_id: item.codmer,
              item_name: item.mer,
              sku: item.codbar,
              price: item.valor,
              item_category: '',
              id: item.codmer,
              quantity: +item.quantidade
            }))
          }
        });

        toast.success('Pedido enviado com sucesso');
        setCarrinho([]);
        navigate('/pedidoFinalizado');
      }

    } catch (error: any) {
      toast.error('Falha ao finalizar pedido. ' + error.message);
    } finally {
      setTimeout(() => {
        localStorage.removeItem('@Cupom');
        setIsLoadingPost(false);
      }, 1000);

    }
  }

  async function buscarDadosPorCep(event: React.FocusEvent<HTMLInputElement, Element>) {
    if (event.target.value.length === 8) {
      setIsLoadingPost(true);

      const dadosEndereco = await BuscaEndereco(event.target.value);

      if (dadosEndereco) {
        setDadEnt((prev) => ({
          ...prev,
          log: dadosEndereco.logradouro,
          num: '',
          bai: dadosEndereco.bairro,
          cid: dadosEndereco.localidade,
          uf: dadosEndereco.uf,
          codibg: dadosEndereco.ibge
        }));
        setValFre(0);
        setMulFre([]);
        setMulFreSelecionado(undefined);
      }

      setTimeout(() => {
        setIsLoadingPost(false);
      }, 500);
    }
  }

  function handleInputChangeDadEntForm(event: React.ChangeEvent<HTMLInputElement>) {
    const { name, value } = event.target;

    setDadEnt((prev) => ({ ...prev, [name]: value }));
  }

  function handleInputChangeDadPagForm(event: React.ChangeEvent<HTMLInputElement>) {
    const { name, value } = event.target;

    if (name === 'cgc') {
      if (value.length > 14) {
        setDadPag((prev) => ({ ...prev, [name]: cnpjMask(value) }));
        return;
      }

      setDadPag((prev) => ({ ...prev, [name]: cpfMask(value) }));
      return;
    }

    if (name === 'datExp') {
      if (value === '/') {
        setDadPag((prev) => ({ ...prev, [name]: '' }));
        return;
      }

      setDadPag((prev) => ({ ...prev, [name]: dataExpiracaoCartaoMask(value) }));
      return;
    }

    setDadPag((prev) => ({ ...prev, [name]: value }));
  }

  function handleInputFocus(event: any) {
    setDadPag((prev) => ({ ...prev, focus: event.target.name }));
  }
  function Modal() {
    async function copyToClipBoard(text: string) {
      function selecionarTexto(elemento: HTMLElement) {
        const selecao: Selection | null = window.getSelection();

        if (selecao) {
          selecao.removeAllRanges();
          const range = document.createRange();
          range.selectNodeContents(elemento);
          selecao.addRange(range);
        }
      }

      try {
        if (!navigator?.clipboard) {
          const elementoParaCopiar = document.getElementById('qrCodeString');

          if (elementoParaCopiar) {
            selecionarTexto(elementoParaCopiar);
            return;
          }

          toast.warning('Seu navegador não suporta esta funcionalidade');
          return;
        }

        await navigator.clipboard.writeText(text);
        toast.success('Copiado com sucesso!');
      }
      catch (err) {
        toast.error('Falha ao copiar.');
      }
    }

    return (
      <ReactModal
        isOpen={modalVisible}
        appElement={document.getElementById('root') as HTMLElement}
        shouldCloseOnOverlayClick={false}
        onRequestClose={() => toast.warning('Aguardando Pagamento')}
        style={{
          overlay: {
            position: 'fixed',
            backgroundColor: 'rgba(0, 0, 0, 0.75)',
            zIndex: 9999,
          },
          content: {
            display: 'flex',
            flexDirection: 'column',
            height: 'auto',
            maxHeight: isMobile ? '90%' : '70%',
            maxWidth: isMobile ? '90%' : '40%',
            margin: 'auto',
            overflow: 'auto'
          },
        }}
      >
        <h2 style={{ textAlign: 'center' }}>Seu QR Code Para Pagamento</h2>
        {pixPayment.qrCodeBase64Image && <img src={`data:image/png;base64,${pixPayment.qrCodeBase64Image}`} style={{ height: 200, width: 200, alignSelf: 'center' }} />}
        <b style={{ textAlign: 'start' }}>Se Preferir, Copie o Código:</b>
        <p id='qrCodeString' style={{ textAlign: 'start', wordWrap: 'break-word' }}>{pixPayment.qrCodeString}</p>
        <Button
          onClick={() => copyToClipBoard(pixPayment.qrCodeString)}
        >
          Copiar o Código
          <AiOutlineCopy size={25} color='#fff' />
        </Button>
        <b style={{ textAlign: 'center' }}>Aguardando Pagamento...</b>
      </ReactModal>
    );
  }

  async function getDadosPedido(codped: string, username: string) {
    try {
      const response = await api.get(`/pedidos/listarPedidoPorCliente?nome=${username}&cod=${codped}`);

      if (response.status === 200 && response.data.length > 0) {
        const carrinhoPedido: ICarrinho[] = response.data[0].itensPedido.map((itemCarrinho: any) => {
          return {
            cod: itemCarrinho?.codIteCar,
            codmer: itemCarrinho.codmer,
            codbar: itemCarrinho.codbar,
            mer: itemCarrinho.mer,
            codtam: itemCarrinho.codtam,
            cor: {
              cod: itemCarrinho.codpad,
              padmer: itemCarrinho.codpad || 'Única',
              linkFot: semFoto
            },
            quantidade: itemCarrinho.qua,
            valor: itemCarrinho.valUni
          };
        });

        const tempSubTot = carrinhoPedido.reduce(
          (accumulator: number, currentValue: ICarrinho) => accumulator + currentValue?.valor * +currentValue?.quantidade,
          0);

        setCarrinho(carrinhoPedido);
        setCupom({ valor: response.data[0].valDes, cod: -1 });
        setValFre(response.data[0].valFre);
        setForPag(response.data[0].forPag);
        getQuantidadeParcelas(true, tempSubTot, response.data[0]?.valFre ?? 0);
      }
    } catch (error: any) {
      toast.error('Falha ao buscar dados do pedido. ' + error.message);
    }
  }

  async function getQuantidadeParcelas(usaCheTra: boolean, subTot: number, valFre: number) {
    try {
      if (!usaCheTra) return;

      const response = await api.get('/quaparsit');

      if (response.status === 200 && response.data.length > 0) {

        const quaParSit: IQuaParSit[] = response.data;

        quaParSit.forEach(parSit => {
          if (subTot + valFre >= parSit.valcar) {
            const totNumPar: ISelectOptions[] = [];

            for (let i = 0; i < parSit.quapar; i++) {
              totNumPar.push({ value: (i + 1).toString(), text: (i + 1) + 'x ' });
            }

            setTotNumPar(totNumPar);
          }
        });
      }

    } catch (error: any) {
      toast.error('Falha ao buscar quantidade de parcelas. ' + error.message);
    }
  }

  useEffect(() => {
    if (configs.length > 0) {
      const [{ val: uri }] = configs.filter((config: IConfig) => config.gru === 'logo');
      const [{ val: keyApiGoo }] = configs.filter((config: IConfig) => config.con === 'KeyApiGoo');
      const [{ val: usaDadExtEnt }] = configs.filter((config: IConfig) => config.con === 'UsaDadExtEnt');
      const [{ val: usaOpcPagRetLoj }] = configs.filter((config: IConfig) => config.con === 'UsaOpcPagRetLoj');
      const [{ val: cieMerId }] = configs.filter((config: IConfig) => config.con === 'CieMerId');
      const [{ val: tokPagSeg }] = configs.filter((config: IConfig) => config.con === 'TokPagSeg');
      const [{ val: quaMaxPar }] = configs.filter((config: IConfig) => config.con === 'quamaxpar');
      const [{ val: valminpar }] = configs.filter((config: IConfig) => config.con === 'valminpar');
      const [{ val: valfregra }] = configs.filter((config: IConfig) => config.con === 'valfregra');
      const [{ val: ValMinCom }] = configs.filter((config: IConfig) => config.con === 'ValMinCom');
      const [{ val: usaCheTra }] = configs.filter((config: IConfig) => config.con === 'usaCheTra');

      setLogoURI('https://' + uri);
      setKeyApiGoo(keyApiGoo);
      setUsaDadExtEnt(Boolean(+usaDadExtEnt));
      setUsaOpcPagRetLoj(Boolean(+usaOpcPagRetLoj));
      setUsaCielo(Boolean(cieMerId));
      setUsaPagSeguro(Boolean(tokPagSeg));
      setQuaMaxPar(+quaMaxPar);
      setValMinPar(+valminpar);
      setValFreGra(valfregra);
      setValMinCom(ValMinCom);
      setUsaCheTra(Boolean(+usaCheTra));
    }
  }, [configs]);

  useEffect(() => {
    if (carrinho.length > 0) {
      setItensCarrinho(carrinho);
      setSubTot(carrinho.reduce(
        (accumulator: any, currentValue: any) => accumulator + currentValue?.valor * +currentValue?.quantidade,
        0));
    }
  }, [carrinho]);

  useEffect(() => {
    if (dadosLogin.id === 0) {
      toast.warning('Faça login para finalizar o pedido.');
      navigate('/login');
      return;
    }

    if (carrinho.length === 0 && !codped) {
      toast.warning('Seu carrinho está vazio, monte seu carrinho antes de fechar seu pedido.');
      navigate('/');
      return;
    }

    if (codped) {
      getDadosPedido(codped, dadosLogin.username);
      setIndexCarousel(2);
      toast.success('Selecione a forma de pagamento.');
    }

    gtmEventPush('begin_checkout', {
      ecommerce: {
        currency: 'BRL',
        value: carrinho.reduce(
          (accumulator: any, currentValue: any) => accumulator + currentValue?.valor * +currentValue?.quantidade,
          0),
        items: carrinho.map((item: ICarrinho) => ({
          item_id: item.codmer,
          item_name: item.mer,
          sku: item.codbar,
          price: item.valor,
          item_category: '',
          id: item.codmer,
          quantity: +item.quantidade
        }))
      }
    });

    return () => {
      if (codped) {
        setCarrinho([]);
      }
    };

  }, []);

  return (
    <LoadingOverlay
      active={isLoadingPost}
      spinner
      text='Aguarde...'
    >
      <Modal />
      <Container>
        <ProgressBar
          steps={slides.map((slide, index: number) => {
            return { label: slide.titulo, step: index + 1 };
          })}
          activeStep={indexCarousel + 1}
        />
        <CarouselContainer>
          <Carousel
            showArrows={false}
            showStatus={false}
            showThumbs={false}
            showIndicators={false}
            swipeable={false}
            emulateTouch={true}
            selectedItem={indexCarousel}
            onChange={setIndexCarousel}
          >
            {slides.map((slide, index: number) => (
              <div key={index + 1} className='carousel'>
                <h2>{slide.titulo}</h2>
                {slide.component}
              </div>
            ))}
          </Carousel>
        </CarouselContainer>
      </Container>
    </LoadingOverlay>
  );
}
