import React, { useEffect, useState, useContext, useRef } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import LoadingOverlay from 'react-loading-overlay-ts';
import * as FaIcons from 'react-icons/fa';
import { toast } from 'react-toastify';
import * as AiIcons from 'react-icons/ai';

import {
  Container, FinalizarButton, FinalizarCarrinhoDiv, FinalizarDiv,
  FreteDiv, HiddenDiv, ListaCarrinhoDiv, Logo, LogoDiv, MobilePrecoDiv, PrecoDiv, ProdutoDiv,
  ProdutoMobileDiv, ProdutoMobileImage, ProdutoMobileImageDiv, ProdutoMobileInfoDiv,
  ProdutoNomeDiv, QuantidadeButton, QuantidadeDiv, QuantidadeInput, QuantidadeInputDiv,
  TituloColunasDiv, TotaisDiv, TotaisFinalizarDiv, TotalDiv
} from './styles';

import Context, { ICarrinho, IContext } from '../../context/Context';
import Footer from '../../components/Footer';
import IconeDinamico from '../../components/IconeDinamico';

import { formatCurrency } from '../../utils/formatCurrency';
import useWindowDimensions from '../../utils/WindowDimensions';
import { deleteItemCarrinho, postItemCarrinho } from '../ProdutoDetalhes';
import api from '../../services/api';
import semFoto from '../../assets/images/sem-foto.jpg';
import { gtmEventPush } from '../../utils/gtmEventPush';
import { CupomInputCustom, InputButtonDiv } from '../FinalizarCarrinho/styles';
import { ICupom } from '../FinalizarCarrinho';

export default function Carrinho() {
  const navigate = useNavigate();
  const { width } = useWindowDimensions();
  const isMobile = width <= 767;
  const cupomInputRef = useRef<HTMLInputElement>(null);

  const { configs, carrinho, setCarrinho, dadosLogin }: IContext = useContext(Context);

  const [isLoadingPost, setIsLoadingPost] = useState(false);
  const [itensCarrinho, setItensCarrinho] = useState<ICarrinho[]>([]);
  const [cupom, setCupom] = useState<ICupom>({ valor: 0, cod: 0 });

  //config
  const [logoURI, setLogoURI] = useState<string>('');
  const [finalizarCarrinhoNoWhats, setFinalizarCarrinhoNoWhats] = useState<boolean>(false);
  const [botFinCom, setBotFinCom] = useState<boolean>(false);
  const [numCel, setNumCel] = useState<string>('');
  const [necCadAutMosPro, setNecCadAutMosPro] = useState<boolean>(false);
  const [venAciEst, setVenAciEst] = useState<boolean>(true);
  const [valFreGra, setValFreGra] = useState<string>('0');
  const [valMinCom, setValMinCom] = useState<string>('0');

  const subTotal = itensCarrinho.reduce((accumulator: number, currentValue: ICarrinho) => accumulator + currentValue?.valor * +currentValue?.quantidade, 0);

  async function incrementarQuantidade(codmer: number) {
    const novoCarrinho = itensCarrinho.map((item: ICarrinho) => {
      if (item.codmer === codmer) {
        if (!venAciEst && item?.estatu) {
          if (+item.quantidade + 1 > +item?.estatu) {
            toast.warning(`${item.mer} ${item?.cor?.padmer || ''} ${item?.codtam || ''} - Quantidade inválida. Estoque atual: ` + item?.estatu);
            return item;
          }
        }

        const itemCarrinhoAtualizado = {
          cod: item.cod, codmer: item.codmer,
          codapp_user: dadosLogin.id, qua: +item.quantidade + 1
        };

        postItemCarrinho(itemCarrinhoAtualizado);

        return { ...item, quantidade: String(+item.quantidade + 1) };
      }

      return item;
    });
    setCarrinho(novoCarrinho);
    setItensCarrinho(novoCarrinho);
  }

  function decrementarQuantidade(codmer: number) {
    const novoCarrinho = itensCarrinho.map((item: ICarrinho) => {
      if (item.codmer === codmer) {
        if (+item.quantidade > 1) {
          const itemCarrinhoAtualizado = {
            cod: item.cod, codmer: item.codmer,
            codapp_user: dadosLogin.id, qua: +item.quantidade - 1
          };
          postItemCarrinho(itemCarrinhoAtualizado);
          return { ...item, quantidade: String(+item.quantidade - 1) };
        }
        deleteItemCarrinho(item.cod);
        return;
      }
      return item;
    });

    const novoCarrinhoFilter = novoCarrinho.filter((carrinho: any) => carrinho);
    setCarrinho(novoCarrinhoFilter);
    setItensCarrinho(novoCarrinhoFilter);
  }

  function alterarQuantidade(quantidade: number | string, codmer: number) {
    if (quantidade == '0') {
      toast.warning('Quantidade inválida');
      return;
    }

    const novoCarrinho = itensCarrinho.map((item: ICarrinho) => {
      if (item.codmer === codmer) {
        const itemCarrinhoAtualizado = {
          cod: item.cod, codmer: item.codmer,
          codapp_user: dadosLogin.id, qua: Math.round(+quantidade)
        };
        postItemCarrinho(itemCarrinhoAtualizado);
        return { ...item, quantidade: String(Math.round(+quantidade)) };
      }
      return item;
    });
    setCarrinho(novoCarrinho);
    setItensCarrinho(novoCarrinho);
  }

  function validaFrete(): string {
    if (valFreGra === '0') return 'A Calcular';
    if (subTotal >= +valFreGra) return 'Grátis';

    return 'A Calcular';
  }

  async function finalizarPeloWhatsapp() {
    try {

      if (valMinCom !== '0' && subTotal < +valMinCom) {
        const falta = +valMinCom - subTotal;

        toast.warning(`Valor minimo de compra: ${formatCurrency(+valMinCom)} em produtos. Adicione mais ${formatCurrency(falta)}`);
        return;
      }

      setIsLoadingPost(true);

      if (!venAciEst) {
        const estoqueValido = await validaEstoqueCarrinho(itensCarrinho);

        if (!estoqueValido) return;
      }

      if (dadosLogin.id !== 0) {
        const date = new Date();
        const dathor = date.toISOString();

        const itensPedido = itensCarrinho.map((itemCarrinho: ICarrinho) => {
          return { obs: '', qua: itemCarrinho.quantidade, valuni: itemCarrinho.valor, mercador: { cod: itemCarrinho.codmer, mer: itemCarrinho.mer } };
        });

        const pedidoPayload: any = {
          cod: '', codcat: null, dathor: dathor,
          forpag: '', obs: 'Concluir compra pelo WhatsApp', sta: 'WhatsApp',
          valfre: 0, valpro: subTotal.toFixed(2),
          appuser: {
            id: dadosLogin.id
          }
        };

        pedidoPayload.itensPedido = itensPedido;

        await api.post('/pedidos/salvarPed', pedidoPayload, {
          headers: {
            'Content-Type': 'application/json',
          }
        });
      }

      gtmEventPush('begin_checkout', {
        ecommerce: {
          currency: 'BRL',
          value: subTotal,
          items: carrinho.map((item: ICarrinho) => ({
            item_id: item.codmer,
            item_name: item.mer,
            sku: item.codbar,
            price: item.valor,
            item_category: '',
            id: item.codmer,
            quantity: +item.quantidade
          }))
        }
      });

      const produtos = itensCarrinho.map((item: ICarrinho) => {
        return `Ref: ${item.codbar} ` + item.mer + ` Tamanho: ${item.codtam ?? ''}` + ` Cor: ${item.cor.padmer ?? ''}` + ` Qtde: ${item.quantidade}` + (necCadAutMosPro ? dadosLogin.autverprosit === 1 ? ` Vlr Unitário: ${formatCurrency(item.valor)}` + '%0A' : '%0A' : ` Vlr Unitário: ${formatCurrency(item.valor)}` + '%0A');
      });

      const url = `https://api.whatsapp.com/send?phone=55${numCel.replace(/\D/g, '')}&text=Olá! Me chamo ${dadosLogin.raz}, vim pelo site e gostaria de finalizar meu carrinho:%0A${produtos}`;

      setCarrinho([]);
      navigate('/');

      //Coloquei timeout e _blank para funcionar no safari ios
      setTimeout(() => {
        window.open(url, '_blank');
      });

    } catch (error: any) {
      toast.error('Falha ao finalizar compra pelo WhatsApp. ' + error.message);
    } finally {
      setIsLoadingPost(false);
    }
  }

  async function validaEstoqueCarrinho(itens: ICarrinho[]) {
    try {
      const listaDeCodMer = itens.map((item: ICarrinho) => item.codmer.toString());

      if (listaDeCodMer.length === 0) {
        return false;
      }

      const response = await api.get(`/mercador/listarPorCod?cod=${listaDeCodMer.join(',')}`);

      const itensSemEstoque: any = itens.map((item: ICarrinho) => {
        const itemEncontrado = response.data.find((mer: any) => mer.cod === item.codmer);
        item.estatu = itemEncontrado?.estest;

        if (itemEncontrado && item.quantidade > itemEncontrado.estest) {
          return item;
        }

        return;
      }).filter((item: any) => item);

      if (itensSemEstoque.length === 0) return true;

      itensSemEstoque.forEach((item: ICarrinho) => {
        toast.error(`Estoque insuficiente: ${item.mer} ${item?.cor?.padmer || ''} ${item?.codtam || ''}. Estoque atual: ` + item?.estatu);
      });

      return false;

    } catch (error: any) {
      toast.error('Falha ao validar estoque. ' + error.message);
    }
  }

  async function validaCupom(cupDes: string) {
    try {
      if (!cupDes) return;

      const response = await api.get('/cupdes', {
        params: {
          cupdes: cupDes,
          codusu: dadosLogin.id,
          valcar: subTotal
        }
      });

      if (response.status === 200) {
        if (response.data?.status !== 0) {
          localStorage.removeItem('@Cupom');
          toast.warning(response.data?.mensagem || 'Cupom inválido ou expirado');
          return;
        }

        let valorCupom = response.data.valor;

        if (response.data.tipoValor === 'P') {
          valorCupom = subTotal * (response.data.valor / 100);
        }

        //Caso valor de desconto do cupom for maior que o valor total do carrinho
        if (valorCupom >= subTotal) {
          valorCupom = subTotal;
        }

        setCupom({ valor: valorCupom, cod: response.data.cod });
        localStorage.setItem('@Cupom', cupDes);
        toast.success('Cupom de desconto aplicado');
        return;
      }

      if (cupom.valor > 0) {
        setCupom({ valor: 0, cod: 0 });
      }

      localStorage.removeItem('@Cupom');
      toast.warning('Cupom inválido ou expirado');
    } catch (error: any) {
      toast.error('Falha ao aplicar cupom. ' + error.message);
      localStorage.removeItem('@Cupom');
      setCupom({ valor: 0, cod: 0 });
    }
  }

  function cupomInputKeyPress(event: React.KeyboardEvent<HTMLInputElement>) {
    if (event.key === 'Enter') {
      if (event.target instanceof HTMLInputElement) {
        const value = event.target.value;

        validaCupom(value);
      }
    }
  }

  async function finalizarCarrinho() {
    try {

      if (dadosLogin.id === 0) {
        toast.warning('Faça login para finalizar seu pedido');
        navigate('/login');
        return;
      }

      if (necCadAutMosPro && !dadosLogin?.autverprosit) {
        toast.warning(`Conta não autorizada, entre em contato com a loja. ${numCel}`);
        return;
      }

      if (valMinCom !== '0' && subTotal < +valMinCom) {
        const falta = +valMinCom - subTotal;

        toast.warning(`Valor minimo de compra: ${formatCurrency(+valMinCom)} em produtos. Adicione mais ${formatCurrency(falta)}`);
        return;
      }

      setIsLoadingPost(true);

      if (!venAciEst) {
        const estoqueValido = await validaEstoqueCarrinho(itensCarrinho);

        if (!estoqueValido) return;
      }

      navigate('/finalizarCarrinho');
    } catch (error: any) {
      toast.error('Falha ao finalizar pedido por WhatsApp. ' + error.message);
    } finally {
      setIsLoadingPost(false);
    }
  }

  function Preco({ preço }: { preço: number }) {
    if (necCadAutMosPro) {
      if (!dadosLogin.autverprosit) {
        return <span>-</span>;
      }
    }
    return <span>{formatCurrency(preço)}</span>;
  }

  function ValorMinimoCompraCaption() {
    if (!dadosLogin?.autverprosit) {
      return (
        <></>
      );
    }

    if (valMinCom !== '0' && subTotal < +valMinCom) {
      const falta = +valMinCom - subTotal;

      return (
        <p style={{ width: '100%', color: 'red', textAlign: 'justify', cursor: 'default' }}>
          Valor minimo de compra: {formatCurrency(+valMinCom)} em produtos. Adicione mais {formatCurrency(falta)}
        </p>
      );
    }

    return (
      <></>
    );
  }

  useEffect(() => {
    setTimeout(() => {
      window.scrollTo({ top: 0, behavior: 'smooth' });
    }, 100);
  }, []);

  useEffect(() => {
    if (configs.length > 0) {
      const [{ val: falarComVendedor }] = configs.filter((config: any) => config.con === 'botFalVen');
      const [{ val: botFinCom }] = configs.filter((config: any) => config.con === 'botFinCom');
      const [{ val: uri }] = configs.filter((config: any) => config.gru === 'logo');
      const [{ val: numWha }] = configs.filter((config: any) => config.con === 'NumWha');
      const [{ val: CadAutMosPro }] = configs.filter((config: any) => config.con === 'NecCadAutMosPro');
      const [{ val: VenAciEst }] = configs.filter((config: any) => config.con === 'VenAciEst');
      const [{ val: valFreGra }] = configs.filter((config: any) => config.con === 'valfregra');
      const [{ val: ValMinCom }] = configs.filter((config: any) => config.con === 'ValMinCom');

      setFinalizarCarrinhoNoWhats(Boolean(JSON.parse(falarComVendedor ?? 0)));
      setBotFinCom(Boolean(JSON.parse(botFinCom ?? 0)));
      setLogoURI('https://' + uri);
      setNumCel(numWha);
      setNecCadAutMosPro(Boolean(+CadAutMosPro));
      setVenAciEst(Boolean(+VenAciEst));
      setValFreGra(valFreGra);
      setValMinCom(ValMinCom);
    }
  }, [configs]);

  useEffect(() => {
    if (carrinho.length > 0) {
      setItensCarrinho(carrinho);
    }
  }, [carrinho]);

  return (
    <LoadingOverlay
      active={isLoadingPost}
      spinner
      text='Aguarde...'
    >
      <Container>
        <LogoDiv>
          <HiddenDiv />
          <Link to={'/'}>
            {logoURI && <Logo src={logoURI} alt='Logo da Empresa' />}
          </Link>
          <span>Compra 100% Segura</span>
        </LogoDiv>
        <hr />
        <b>Confira seu carrinho</b>
        <ListaCarrinhoDiv>
          <TituloColunasDiv>
            <span className='produto'>Lista de Produtos</span>
            <span className='preco'>Preço Unitário</span>
            <span className='quantidade'>Quantidade</span>
            <span className='total'>Total</span>
          </TituloColunasDiv>
          {itensCarrinho.map((itemCarrinho: ICarrinho, index: number) =>
            itemCarrinho && !isMobile ?
              <ProdutoDiv key={index}>
                <ProdutoNomeDiv
                  onClick={() => navigate(`/produtoDetalhes/${itemCarrinho.codbar}/${itemCarrinho.mer.replaceAll(' ', '-')}`,
                    { state: { caminho: 'Home' } })
                  }
                >
                  <img
                    src={itemCarrinho?.cor?.linkFot}
                    title={itemCarrinho.cor.padmer}
                    alt={`Imagem do produto ${itemCarrinho.mer}`}
                    onError={(e: any) => {
                      e.target.onerror = null; // Evita loops infinitos caso a imagem de substituição também não seja encontrada
                      e.target.src = semFoto;
                    }}
                  />
                  <span>{itemCarrinho?.mer}{' '}{itemCarrinho.codtam}</span>
                </ProdutoNomeDiv>
                <PrecoDiv>
                  <Preco preço={itemCarrinho?.valor} />
                </PrecoDiv>
                <QuantidadeDiv>
                  <QuantidadeInputDiv>
                    <QuantidadeButton
                      onClick={() => decrementarQuantidade(itemCarrinho?.codmer)}
                    >
                      {itemCarrinho.quantidade == '1' ? <IconeDinamico nome='FaTrashAlt' size={15} /> : '-'}
                    </QuantidadeButton>
                    <QuantidadeInput
                      type={'number'}
                      value={itemCarrinho.quantidade}
                      isInvalid={!venAciEst && itemCarrinho?.estatu ? +itemCarrinho.quantidade > itemCarrinho.estatu : false}
                      onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
                        if (+e.target.value < 0) {
                          return;
                        }
                        alterarQuantidade(e.target.value, itemCarrinho?.codmer);
                      }}
                      onBlur={(e: React.ChangeEvent<HTMLInputElement>) => {
                        if (!venAciEst && itemCarrinho?.estatu) {
                          if (+e.target.value > itemCarrinho.estatu) {
                            alterarQuantidade(itemCarrinho.estatu, itemCarrinho?.codmer);
                            toast.warning(`${itemCarrinho.mer} ${itemCarrinho?.cor?.padmer || ''} ${itemCarrinho?.codtam || ''} - Quantidade inválida. Estoque atual: ` + itemCarrinho?.estatu);
                            return;
                          }
                        }

                        if (e.target.value === '' || e.target.value === '0') {
                          alterarQuantidade('1', itemCarrinho?.codmer);
                        }
                      }}
                    />
                    <QuantidadeButton
                      onClick={() => incrementarQuantidade(itemCarrinho?.codmer)}>
                      +
                    </QuantidadeButton>
                  </QuantidadeInputDiv>
                </QuantidadeDiv>
                <TotalDiv>
                  {necCadAutMosPro ? dadosLogin.autverprosit === 1 ?
                    <span>{formatCurrency(itemCarrinho?.valor * +(itemCarrinho?.quantidade))}</span> :
                    <span >-</span> : <span>{formatCurrency(itemCarrinho?.valor * +(itemCarrinho?.quantidade))}</span>
                  }
                </TotalDiv>
              </ProdutoDiv> :
              <React.Fragment key={index}>
                <ProdutoMobileDiv>
                  <ProdutoMobileImageDiv
                    onClick={() => navigate(`/produtoDetalhes/${itemCarrinho.codbar}/${itemCarrinho.mer.replaceAll(' ', '-')}`,
                      { state: { caminho: 'Home' } })
                    }
                  >
                    <ProdutoMobileImage
                      src={itemCarrinho?.cor?.linkFot}
                      alt={`Imagem do produto ${itemCarrinho?.mer}`}
                      onError={(e: any) => {
                        e.target.onerror = null; // Evita loops infinitos caso a imagem de substituição também não seja encontrada
                        e.target.src = semFoto;
                      }}
                    />
                  </ProdutoMobileImageDiv>
                  <ProdutoMobileInfoDiv>
                    <span>{itemCarrinho?.mer}{' '}{itemCarrinho?.codtam}{' '}{itemCarrinho?.cor?.padmer}</span>
                    <MobilePrecoDiv>
                      {necCadAutMosPro ? dadosLogin.autverprosit === 1 ?
                        <b>{formatCurrency(itemCarrinho?.valor)}/Un</b> : <b></b> :
                        <b>{formatCurrency(itemCarrinho?.valor)}/Un</b>
                      }
                      <div />
                      <QuantidadeDiv>
                        <QuantidadeInputDiv>
                          <QuantidadeButton
                            onClick={() => decrementarQuantidade(itemCarrinho?.codmer)}
                          >
                            -
                          </QuantidadeButton>
                          <QuantidadeInput
                            type={'number'}
                            value={itemCarrinho.quantidade}
                            isInvalid={!venAciEst && itemCarrinho?.estatu ? +itemCarrinho.quantidade > itemCarrinho.estatu : false}
                            onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
                              if (+e.target.value < 0) {
                                return;
                              }
                              alterarQuantidade(e.target.value, itemCarrinho?.codmer);
                            }}
                            onBlur={(e: React.ChangeEvent<HTMLInputElement>) => {
                              if (!venAciEst && itemCarrinho?.estatu) {
                                if (+e.target.value > itemCarrinho.estatu) {
                                  alterarQuantidade(itemCarrinho.estatu, itemCarrinho?.codmer);
                                  toast.warning(`${itemCarrinho.mer} ${itemCarrinho?.cor?.padmer || ''} ${itemCarrinho?.codtam || ''} - Quantidade inválida. Estoque atual: ` + itemCarrinho?.estatu);
                                  return;
                                }
                              }

                              if (e.target.value === '' || e.target.value === '0') {
                                alterarQuantidade('1', itemCarrinho?.codmer);
                              }
                            }}
                          />
                          <QuantidadeButton
                            onClick={() => incrementarQuantidade(itemCarrinho?.codmer)}
                          >
                            +
                          </QuantidadeButton>
                        </QuantidadeInputDiv>
                      </QuantidadeDiv>
                    </MobilePrecoDiv>
                  </ProdutoMobileInfoDiv>
                </ProdutoMobileDiv>
                <hr />
              </React.Fragment>
          )}
        </ListaCarrinhoDiv>
        <FinalizarDiv>
          <FreteDiv>
          </FreteDiv>
          <FinalizarCarrinhoDiv>
            <label>Cupom de Desconto:</label>
            <InputButtonDiv>
              <CupomInputCustom
                ref={cupomInputRef}
                disabled={subTotal <= 0 || cupom.valor !== 0}
                onKeyDown={cupomInputKeyPress}
              />
              <button
                onClick={() => {
                  if (cupom.valor === 0) {
                    validaCupom(cupomInputRef.current!.value);
                    return;
                  }

                  cupomInputRef.current!.value = '';
                  setCupom({ valor: 0, cod: 0 });
                }}
              >
                {cupom.valor === 0 ? 'Aplicar' : 'Remover'}
              </button>
            </InputButtonDiv>
            <br />
            <TotaisDiv>
              <TotaisFinalizarDiv>
                <span>SubTotal</span>
                {necCadAutMosPro ? dadosLogin.autverprosit === 1 ?
                  <span>
                    {formatCurrency(subTotal)}
                  </span> : <span>-</span> :
                  <span>
                    {formatCurrency(subTotal)}
                  </span>
                }
              </TotaisFinalizarDiv>
              {cupom.valor > 0 && <TotaisFinalizarDiv>
                <span>Desconto</span>
                <span>
                  {formatCurrency(cupom.valor)}
                </span>
              </TotaisFinalizarDiv>}
              <TotaisFinalizarDiv>
                <span>Frete</span>
                <span>
                  {validaFrete()}
                </span>
              </TotaisFinalizarDiv>
              <TotaisFinalizarDiv>
                <b>Total</b>
                {necCadAutMosPro ? dadosLogin.autverprosit === 1 ?
                  <b>
                    {formatCurrency(subTotal - cupom.valor)}
                  </b> : <b>-</b> :
                  <b>
                    {formatCurrency(subTotal - cupom.valor)}
                  </b>
                }
              </TotaisFinalizarDiv>
            </TotaisDiv>
            <ValorMinimoCompraCaption />
            {botFinCom &&
              <FinalizarButton
                disabled={carrinho.length === 0}
                onClick={finalizarCarrinho}
              >
                Finalizar Compra
                <AiIcons.AiOutlineShoppingCart style={{ marginLeft: 10 }} size={25} />
              </FinalizarButton>
            }
            {finalizarCarrinhoNoWhats &&
              <FinalizarButton
                backgroundColor='#25D366'
                disabled={carrinho.length === 0}
                onClick={finalizarPeloWhatsapp}
              >
                Finalizar Pelo Whatsapp
                <FaIcons.FaWhatsapp style={{ marginLeft: 10 }} size={25} />
              </FinalizarButton>
            }
            <FinalizarButton
              onClick={() => navigate('/')}
              backgroundColor='#229a00'
            >
              Continuar Comprando
              <AiIcons.AiOutlineShopping style={{ marginLeft: 10 }} size={25} />
            </FinalizarButton>
          </FinalizarCarrinhoDiv>
        </FinalizarDiv>
        <Footer />
      </Container>
    </LoadingOverlay>
  );
}
