import { useEffect } from 'react';
import { useLocation } from 'react-router-dom';
import { gtmEventPush } from './gtmEventPush';

export default function usePageTracking() {
  const location = useLocation();

  useEffect(() => {
    gtmEventPush('page_view', {
      url: window.location.origin + location.pathname + location.search,
      page_path: window.location.origin + location.pathname + location.search,
    });
  }, [location]);
}
